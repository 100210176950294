<template>
    <div class='main-title container-xl p-0 d-flex flex-row align-items-center justify-content-between'>
        <h1 class="d-flex flex-row align-items-center">
            {{this.$store.state.title}}
            <Tooltip
                v-if="this.$store.state.titleHint"
                placement='bottom'
                :content='this.$store.state.titleHint'
            />
        </h1>
        <span v-if="this.sub_title" class="grey fs_11">{{ this.sub_title }}</span>
    </div>
</template>

<script>
import Tooltip from '@/components/service/Tooltip';
export default {
    components: {
        Tooltip
    },
    props: {
        sub_title: [String, Boolean]
    }
}
</script>

<style lang="scss">
.main-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-family: 'HelveticaNeue roman';
    h1 {
        font-size: 1.9rem;
    }
    h2 {
        font-size: 1.4rem;
    }
    font-weight: 100;
}
</style>