export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Впервые здесь?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте аккаунт"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон или e-mail:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановить"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь найден"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент/Операция"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры/Комментарий"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операций не найдено"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма операций"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступления:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" шт. на сумму"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типы операций"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты, оплата услуг"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние переводы"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата счета"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы по своим счетам"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание крипто чеков"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активация крипто чеков"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прочее"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приход/расход"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приход"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расход"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= равно"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ не равно"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> больше"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ больше или равно"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< меньше"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ меньше или равно"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно завершена"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В обработке"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История операций"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение счета"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет не выбран"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен на бирже"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение адреса"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы внутри своего кошелька"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цифровой паспорт"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата регистрации:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя авторизация:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активы"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Смотреть все"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счетов нет"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль подтверждения"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для подтверждения операций вам необходимо создать пароль подтверждения."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные платежи"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свернуть"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активов нет"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователю"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий баланс"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать баланс"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрационные сведения"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждено"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общедоступно"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В группе"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы платежей"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежи"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма платежа"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет перевода"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополняемый счет"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способ платежа"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение счета"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты перевода"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить перевод"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о переводах"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкция"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение адреса"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополняемый адрес"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К списку задач"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес перевода"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить операцию"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История операций"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список счетов"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная ошибка"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список токенов"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет перевода:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекция сделки"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок протекции:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как шаблон"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод другому пользователю"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекции — это код, который должен ввести получатель для завершения сделки. Защищенный перевод получатель увидит сразу же в разделе \"История операций\", но воспользоваться деньгами сможет только после того, как введет код протекции в карточке транзакции. Если срок действия платежа истек, то средства возвращаются отправителю."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес перевода"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Откуда"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куда"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевести"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод по своим счетам"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переводы внутри кошелька"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остаток"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активен"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый адрес"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый счет"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить выбранные"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активы"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список счетов"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название счета"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить счета?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить счет?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активов не найдено"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счетов не найдено"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименование адреса"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить адреса?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить адрес?"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нефиатные"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиатные"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип адреса"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип счета"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать адрес"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счет"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание нового адреса"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание нового счета"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка антифишинга является дополнительным механизмом защиты при работе с кабинетом."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заданная вами приветственная фраза будет отображаться в верхней части любой страницы кабинета."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Правильная фраза покажет вам, что вы находитесь на правильном сайте, неверная фраза явится сигналом: не осуществляя операций, необходимо срочно связаться со службой безопасности нашей системы."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить антифишинг:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка антифишинга:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Антифишинг"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения вступят в силу после следующей авторизации"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка доступа по IP-адресу не позволит Вам (или злоумышленнику) работать с системой с адреса, не прописанного в списке разрешённых."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение доступа к кабинету по IP-адресам"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адреса"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блок адресов: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список адресов"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С адреса"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По адрес"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса отсутствуют"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запретить"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите IP-адрес"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес введен неверно"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не указан ни один IP-адрес или блок адресов."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ни один IP-адрес не разрешен для авторизации. Вы не сможете получить доступ к своему счету."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ по IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово - это дополнительная настройка безопасности."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если волшебное слово включено, то каждый раз при авторизации система будет просить вас ввести какие-то символы (по их порядковому номеру) из волшебного слова."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спрашивать волшебное слово:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При входе в кабинет:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При оплате через мерчант:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При использовании карты:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Волшебное слово"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовые ключи значительно повышают безопасность системы."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момент входа программы вас попросят не только ввести логин и пароль, но и очередной одноразовый ключ. Активация производится поочередно. Последний ключ действует до момента, пока вы не создадите новую серию ключей."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать одноразовые ключи"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список ключей"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ключ"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одноразовые ключи"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новый пароль\" и \"Подтверждение пароля\" не совпадают."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение пароля"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка от 4 до 63 символов. Допустимы символы латинского алфавита, цифры и символы"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение пароля:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Новый пароль\" и \"Подтверждение пароля\" не совпадают."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен содержать кириллицу"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание пароля подтверждения"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение пароля подтверждения"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На главную"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка от 4 до 65 символов. Допустимы символы латинского алфавита, цифры и символы"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае, если вы забудете пароль для доступа к кабинету, вы сможете восстановить его, ответив на секретный вопрос."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В данном разделе вы можете сменить секретные вопрос/ответ."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный вопрос"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш вопрос:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный вопрос"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой вопрос"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остаток "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговая пара:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обмена:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод по рынку - будет использован обмен по самому выгодному курсу на момент исполнения заявки. Внимание, сумма к получению может измениться и отличаться от рассчитанной в зависимости от заявок на момент обмена."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод по фиксированной цене происходит в соотвествии с указанной вами суммой и курсом. Будет выставлена заявка. Если указанный курс сильно отличается от рыночного, то закрытие заявки может занять много времени."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс (прогноз):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс не определен, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рекомендуется задать курс самостоятельно"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать все"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По рынку"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(быстрый обмен по лучшему встречному предложению)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированный курс "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(выставить заявку с заданным курсом)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет подходящих счетов"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение за 24 часа"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колебания за 24 часа"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем сделок за 24 часа"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма не может быть равна нулю!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обменять"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы увидеть данные сводки, выберите обменную пару"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нельзя обменивать одинаковые активы"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биржа"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок. Сводка"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похожие предложения"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встречные предложения"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдают"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получают"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои заявки"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние сделки"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдано"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет предложений"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сделок"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет заявок"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью выполнена"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка не начата"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка начата"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью отменена"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена и отменена"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получено"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все заявки"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все заявки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комиссия"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет заявок"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная отдаваемая сумма"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная отдаваемая сумма"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная получаемая сумма"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная получаемая сумма"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество попыток совершить транзакцию"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемый актив"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемый актив"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет зачисления"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список субдилеров"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет не выбран"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даты не выбран"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончание"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финиш"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старт"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какая часть зафиксирована, а какая - переменная"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксируется отдаваемая сумма"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксируется сумма к получению"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип заявки"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь желает быстро обменять по лучшим встречным предложениям"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен по строго заданному курсу"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка не начата"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обработка начата"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью выполнена"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полностью отменена"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично выполнена и отменена"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемый актив"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемый актив"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип даты"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдать сумму"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить сумму"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сделок"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет предложений"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложения"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID продавца"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код предложения"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец верифицирован"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только для верифицированных пользователей"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно совершить сделку с самим собой"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать актив"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№  "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список крипто-активов"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет крипто-активов"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выпуск токена"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первичный"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторичный"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопоставлен с другим активом"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробная информация о криптоактиве"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать данные о крипто-активе"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить криптоактив"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список криптоактивов"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токены можно купить в криптомате"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токены можно продать в криптомате"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Токены вторичного рынка"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображение токенов"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для конкретного пользователя"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UID пользователя"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название – это полное название крипто-актива, каким его будут видеть все: сам эмитент, покупатели, продавцы, процессинг и др."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТИКЕР"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикер – это короткое название крипто-актива, сокращенная аббревиатура длиной от 3 до 10 символов"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию, к которой больше подходит ваш крипто-актив"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживаемые форматы: png, jpg, jpeg, svg. Размер файла не более 1 Мб, 500*500 пикселей"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опишите, зачем данный крипто-актив выпускается эмитентом, в какой сфере работает или будет работать, какова его миссия"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель / миссия / назначение:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость единицы:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество знаков после запятой для суммы. Например, точность большинства фиатных валют, таких как доллар США или Евро равна 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество единиц выпускаемого крипто-актива. Сумма эмиссии будет сразу доступна на вашем счете после завершения операции создания крипто-актива."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявленная стоимость единицы:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспеченность:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицо, несущее обязательства:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Решение о выпуске крипто-актива:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспортировать операции в блокчейн:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип блокчейна:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить операции с крипто-активом:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения/Санкции:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите, гражданам или налоговым резидентам каких стран из приведенного списка запрещается использование в своем обороте крипто-актива: "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иные ограничения и условия:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес хостинга:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес белой книги:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие контакты:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать крипто-актив"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название и назначение"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые характеристики"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт в блокчейн"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиты и ограничения"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не определена"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированная привязка к фиатным валютам и иным активам"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент (Лицо, выпускающее крипто-актив)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент (Лицо, распространяющее крипто-актив"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправитель"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я (включить спонсорство)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размещение крипто-актива"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив означает цифровое представление стоимости или права, которое может быть передано и сохранено в электронном виде с использованием технологии блокчейна. Крипто-активы НЕ включают в себя фиатные валюты, ценные бумаги и иные финансовые инструменты и активы, подлежащие отдельному государственному регулированию."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать крипто-актив"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Администрирование"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа в акцепте"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт оферента"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт оферента в валюте привязки"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент - представитель эмитента актива в сделках покупки/продажи на первичном рынке. Счёт пассивный. Может быть счетом эмиссии актива"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт пассивный, в валюте привязан. Должен принадлежать тому же пользователю, что и предыдущий счёт"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить покупку актива пользователями в банкоматах"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить продажу актива пользователями в банкоматах"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма покупки для вторичного рынка"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки на вторичном рынке выполняются через шлюзы"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма продажи для вторичного рынка"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки на вторичном рынке выполняются через шлюзы"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить сделки с активом на бирже"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить сделки с активом через P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае, если Вы являетесь индивидуальным предпринимателем или представителем юридического лица, вам необходимо загрузить файл решения о выпуске цифрового актива в формате PDF. Решение должно содержать все основные характеристики виртуального актива и быть подписано электронной подписью ИП или данного юридического лица. Пример оформления решения о выпуске цифрового актива Вы можете найти на страницах нашего сайта в разделе \"Документы\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размещение виртуального актива"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подставить счёт эмиссии"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать из счетов эмитента"])},
        "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма к списанию:"])},
        "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет счетов в валюте"])},
        "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счет"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виртуальные активы"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка уведомлений"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомлять об операциях"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация (вход в личный кабинет, мобильное приложение и др.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомлять при событии \"Выполнение финансовых операций с помощью карты\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расходы (любые платежи, переводы, оплата услуг и т.д.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступление денежных средств"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение письма по внутренней почте"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поступление счета на оплату"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение настроек безопасности"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать операции кодом из сообщения"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать авторизацию кодом из сообщения"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать при \"Выполнение финансовых операций с помощью карты\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать изменение настроек безопасности кодом из сообщения"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать расходы кодом из сообщения"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения не сохранены, сохранить?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не сохранять"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата поступления"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата обработки"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во платежей"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в т.ч. успешных"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в т.ч. ошибочных"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовые платежи"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщик услуг"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1-й параметр"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежей не найдено"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете сделать одновременно несколько платежей. Для этого Вам нужно предварительно создать файл с описанием платежей в формате \"XLS\", \"XLSX\", \"CSV\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ файла"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарий"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о файле"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего платежей: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на общую сумму: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл платежей"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Провести платежи"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка файла платежей"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Массовые платежи и переводы"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервис массовых платежей позволяет быстро переводить средства другим пользователям и выполнять операции оплаты в адрес поставщиков услуг."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В разделе"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить файл платежей"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вы можете загрузить файл в формате Excel (XLS, XLSX, CSV) или XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список задач"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позволяет посмотреть статусы созданных ранее запросов на массовые выплаты. "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете создать файл в табличном редакторе Excel. "])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать пример файла"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Важные замечания:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат файла - CSV, XLS или XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл должен быть без заголовков."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для того, чтобы длинные числа не превращались в формат экспоненты (например, \"5,46546E+15\") и не отрезались ведущие нули, можно перед числом поставить символ одиночной кавычки ('), либо перед вводом данных задать ячейке текстовый формат."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для платежей в адрес поставщиков услуг"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список колонок в файле:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Указывается из списка операторов, приведенных"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["здесь"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Для всех операторов сотовой связи можно указывать в качестве кода оператора 7000 - в этом случае оператор будет опеределен автоматически по номеру телефона.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в тикере актива, с которого осуществляется перевод. Если такого тикера нет, к операции автоматически применяется системный коэффициент конвертации."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к платежу"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнять не обязательно, можно оставить пустым; длина не более 255 символов."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Прописывается значение параметра, требуемого оператором, например, номер телефона. Многие операторы ограничиваются одним параметром платежа, но если количество параметров больше одного, то Вам необходимо указывать их все в соседних от первого столбцах."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры платежа можно посмотреть перейдя к оплате какого-либо оператора:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для массовых переводов пользователям"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Указывается 0 или вообще не указывается."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма отображается в тикере счета, с которого переводится актив. Если нет такого тикера, к операции автоматически применяется коэффициент пересчета системы."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к платежу"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнять не обязательно, можно оставить пустым; длина не более 255 символов."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет получателя"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Здесь указывается номер счета пользователя, которому необходимо выполнить перевод (20 цифр)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Формат колонок для массовой выдачи займов кредитным агентом"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Эта строка указывает, что платежи представляют собой займы."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Сумма указывается в валюте кредитного продукта."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кредиту"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнять не обязательно, можно оставить пустым; длина не более 255 символов."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID заемщика"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Обратите внимание, что у заемщика должен быть цифровой паспорт Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код кредитного продукта"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Посмотреть его можно в списке кредитных продуктов на ручном предоставлении займа."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Просто число периодов (какие именно периоды используются определено в кредитном продукте, например, это могут быть дни, недели, месяцы и т.д.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код оператора (номер формы)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Заполнение этого и следующих полей не обязательно, если их не заполнять, то заемщик сможет позднее самостоятельно осуществить вывод кредита через свой личный кабинет."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить в случае ошибки"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Отменить (1) выданный кредит, если транзакция вывода завершится с ошибкой, или оставить для последующего самостоятельного вывода (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Прописывается значение параметра, требуемого оператором, например, номер телефона. Многие операторы ограничиваются одним параметром платежа, но если количество параметров больше одного, то Вам необходимо указывать их все в соседних от первого столбцах."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметр платежа N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее сообщение"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет запросов"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приоритет"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст сообщения"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете загрузить один или несколько файлов. Максимальный размер файла - 10Мб."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкий"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормальный"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокий"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Техническая поддержка"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные запросы"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архив"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый запрос"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрабатывается оператором"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос создан"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ведется работа, ожидайте ответа от оператора"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос закрыт"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос закрыт"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новых сообщений нет"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое сообщение от оператора"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новых сообщений нет"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить в архив"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все запросы"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнение"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оператор"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет шаблонов"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить шаблон №"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать шаблон "])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переименовать"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о контрагенте"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Счет / E-mail / Телефон"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Крипто-адрес / E-mail / Телефон"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие сведения"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cведения об оплате"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контрольная подпись"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои магазины"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазинов не найдено"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать код виджета"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать платежную ссылку"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить магазин"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите удалить магазин"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать магазин"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим платежей"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрировать магазин"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрированный в системе электронный магазин может принимать платежи от клиентов через мерчант нашей системы."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В качестве магазина регистрируются электронные магазины, обменные пункты и любые другие интернет-ресурсы, желающие принимать платежи в свой адрес."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название или торговая марка"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL-адрес магазина"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краткое описание магазина"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип сервиса"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин активен"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секретный ключ"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить автовыплаты"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посылать результаты на скрипт"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес скрипта"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат после успешного платежа"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После НЕуспешного платежа"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не менее 30 знаков."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используется для проверки целостности и достоверности передаваемых данных платежной системой (при отправке магазином пользователя на мерчант) и магазином (при получении результатов платежа)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К списку магазинов"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инвойсов"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инвойсов не найдено"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Магазин"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний актив"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимы"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если заполнено, объявление будет доступно только пользователям из указанных стран"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать к активу"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единицы измерения"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенно"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление перевода"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания (мин)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление активно"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только по ссылке"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только верифицированным пользователям"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К предыдущему"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разместить"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание объявления"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свой внешний актив"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не привязывать"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сократить курс"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть обратно"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет счета в таком активе"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счёт"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите курс"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс корректный"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сократить курс"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть обратно"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум больше максимума"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Куда и как хотите получить перевод. Например: Переведите в приложении банка по номеру телефону +79990001111 (получатель Александр Н.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отдаю"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получаю"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрашиваемые реквизиты"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Какие реквизиты должен будет прислать тейкер, чтобы вы смогли сделать ему перевод"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать актив"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать актив"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что хотите получить: средства во внутреннем активе, например, BTC или во внешнем, например TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напишите комментарий об особенностях проведения этой сделки, например: \"Внешний актив передается только при личной встрече\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид ценностей, которому относится внешний актив, например \"Наличные деньги\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название актива для отображения на платформе"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив также может называться словами из списка"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопоставление с существующим активом"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например: литры, штуки, часы, коробки"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например: л, ш, ч, кор"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько может быть цифр после запятой в значениях актива"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы выбрали создание ещё не существующего актива, пожалуйста, заполните его характеристики"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время, которое даётся сторонам для выполнения условий"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренним активом является актив, который существует в системе \"New Reality\". Внешний актив - это актив, не существующий в системе \"New Reality\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не привязан"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить сделку"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить участие в сделке"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться от сделки"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продлить время ожидания"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена сделки"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус юзера в сделке не определен. Что-то пошло не так"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить Арбитраж"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласиться на Арбитраж"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомить о выполнении перевода"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить получение перевода"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцените сделку"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить оценку"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка № "])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сегодня"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завтра"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить Арбитраж"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить арбитраж"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы запросили арбитраж"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Против Вас запросили арбитраж"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет арбитраж (Вы - истец)"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идет арбитраж (Вы - ответчик)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По истечении таймера согласие на арбитраж произойдёт автоматически и вы уже не сможете добавить свои ответы на вопросы арбитража"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдать"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаете"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаете"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиты"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сделок"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет сделок"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложения"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выгода"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код сделки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активных сделок"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои сделки"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корресподент"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание актива"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет объявлений"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои объявления"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои объявления"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое объявление"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний актив"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренний актив"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаю"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаю"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимиты"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата публикации"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остаток на счёте"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос сделки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложение"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продавец"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таймаут"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания действий сторон"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаете"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаете"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить сделку"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать счет"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет не выбран"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма не попадает в диапазон: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мин"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемый"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемый"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["актив"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отдаю"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внутренний"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["получаю"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние активы"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активы не найдены"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешние активы"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внешний"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрано:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешние"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить арбитраж"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласится на арбитраж"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вчера"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешние активы"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязан к активу"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единицы измерения"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период с"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период по"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет активов"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вчера"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Год"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязан к активу"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автор"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Редактировать внешний криптоактив"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить внешний криптоактив"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реквизиты"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальная"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит суммы"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время ожидания (мин)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление активно"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только по ссылке"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно только верифицированным пользователям"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать внешний актив"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синонимы"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страны"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязать к активу"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единицы измерения"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сокращенно"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматы"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите для разворота таблицы на полный экран"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субдилеры"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивно"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать банкомат"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать криптомат"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расписание работы"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание места установки"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нормированный"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ненормированный"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим работы"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понедельник"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторник"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среда"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четверг"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пятница"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суббота"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воскресенье"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеденный перерыв"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повтор пароля"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использовать существующие счета"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новые счета"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основные сведения"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время работы (справочная информация)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкоматы не найдены"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер банкомата"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["широта"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["долгота"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон поддержки"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID субдилера"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счета"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счёт"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзитный счёт"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание банкомата"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование банкомата"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Географические координаты"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запретить"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешить"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инкассаторов"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инкассатора"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно перейти к инкассационным остаткам - нет данных"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список инкассаторов"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инкассатора"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешен"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрещен"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Акцепт"])},
      "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать банкомат"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть текущие инкассационные остатки"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть выгруженные инкассационные остатки"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пин-код"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить банкомат"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если не указывать субдилера, то банкомат будет привязан к вам"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Широта и долгота — это географические координаты, которые можно скопировать, например, из Google Maps, введя адрес банкомата"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Место установки"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для отображения таблицы должен быть выбран хотя бы один столбец"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к устройствам банкомата"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронные чеки"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать чек"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создано"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не активно"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет чеков"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронных чеков не найдено"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание электронного чека"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал каждого чека"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество чеков"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активации:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Созданы чеки"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Экспорт"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активация электронного чека"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет зачисления"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серия"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код активации"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс, произошла ошибка!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация банкоматов"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить инкассацию"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтры"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показывать архивные инкассации"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показывать отмененные инкассации"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет инкассаций"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на инкассацию подана"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать инкассацию"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить статус инкассации"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавление инкассации"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество карт по заявке"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприёмник"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассаторы"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание для инкассаторов"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к инкассации"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка вне очереди"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заменить кассету купюроприёмника"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к картам"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изъять все карты из кассеты Reject диспенсера карт"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изъять все карты из тубы"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер точки - обязательное поле, которое должно быть заполнено"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр в кассете 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма в кассете 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр в кассете 2"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма в кассете 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование инкассации"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер кассеты"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID кассеты"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер инкассации кассеты"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номинал кассеты"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загружено"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактически"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий к кассете"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных для отображения"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация устройства "])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует "])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c "])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по "])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остатки разнесены "])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкомат"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество купюр"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диспенсер"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject/Retract"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купюроприёмник"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject карты"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карты"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["текущие"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выгруженные"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассационные остатки по банкомату "])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация устройства "])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["действует "])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кассета установлена в банкомат "])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изъята "])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остатки разнесены "])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["остатки разнесены "])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" кассета пустая: "])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["слипания: "])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация: "])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" по настоящее время "])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор статуса"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на инкассацию подана"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на инкассацию отменена"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация утверждена к выполнению"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация выполнена кассиром (деньги загружены в кассеты)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассеты переданы инкассаторам (деньги в пути)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация установлена на банкомате"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В результате сбоя перегружена другой инкассацией"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инкассация сменена другой инкассацией на банкомате (остатки в пути)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разнесены инкассационные остатки (инкассация отправлена в архив)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать информацию об инкассаторе"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" номер "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор счёт оферента"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля формы №"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отсутствует обязательный идентификатор"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы успешно авторизовались, продолжите операции на банкомате"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжите операции на банкомате"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать столбцы для отображения"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать отчет\t"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Образец"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лист"])}
    },
    "ATMDevices": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройства банкомата"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить устройство"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип устройства"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кассеты"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить кассету"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип кассеты"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфигурация"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить устройство"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить кассету"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить устройство?"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить кассету?"])}
    },
    "ListRates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютные пары"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать валютную пару"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать валютную пару"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить валютную пару"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемая валюта"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемая валюта"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обновления"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютные пары не найдены"])}
    },
    "RateItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать валютную пару"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валютная пара"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка обновления"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отдаваемая валюта"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получаемая валюта"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлять в курс прибыль, %"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип обновления курса"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок жизни курса, секунд"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интервал обновления курса, секунд"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курс"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На какую дату курс актуален"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алиас отдаваемой валюты"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алиас получаемой валюты"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шлюз"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нужно обязательно выбрать валюту и притом только одну: отдаваемую или получаемую"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать валютную пару"])}
    },
    "SupportNew": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание запроса в поддержку"])}
    },
    "ShowInfoVA": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные крипто-актива"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес эмиссии"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тикер"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель/Миссия/Назначение"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Финансовые характеристики"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точность"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмиссия"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицо, несущее обязательства"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эмитент"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип блокчейна"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость единицы"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обеспеченность"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка в банкоматах"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажа в банкоматах"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки с активом на бирже"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки с активом через P2P"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес хостинга"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес белой книги"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оферент"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие контакты"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персональный аккаунт"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бизнес аккаунт"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество: (если есть)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы буквы латинницы, а также дефис и апостроф."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы буквы латинницы, а также дефис, пробел и апостроф."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы буквы латинницы, а также дефис, кавычки, пробелы, цифры и апостроф."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"My Company\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимы латинские буквы (прописные и строчные), цифры, символы _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные пользователя"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите корректный адрес электронной почты или номер телефона. Номер телефона указывается в международном формате, например: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы успешно зарегистрированы"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь вы можете "])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["авторизоваться в личном кабинете"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш аккаунт будет проверен нашем сотрудником, о результатах его активации мы сообщим вам позже"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выслано письмо со ссылкой для подтверждения регистрации"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На ваш адрес электронной почты"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После первой авторизации вам будет предложено подтвердить номер телефона"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель: "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не найден"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакты"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор пользователей"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успех"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["транзакция "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие параметры"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение остатков на счетах"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На конец операции:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На начало операции:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные параметры"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции в блокчейне"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующая попытка:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Протекция сделки"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок протекции:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код протекции:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Параметры шаблона"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название шаблона :"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить операцию"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить операцию"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить шаблон"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер транзакции"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операция"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корреспондент"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежа"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер платежной формы"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приход/Расход"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение остатков адресов"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить зачисление"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть деньги отправителю"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подозрительная операция"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Варианты действий:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название: "])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не найден"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите пароль подтверждения"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить операцию"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История операций"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список счетов"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успех"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестная ошибка"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои активы"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о новом активе"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список виртуальных активов"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на блокчейн"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввод:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите адрес"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населенный пункт"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регион"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Округ"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Район"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутригородская территория"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Населённый пункт"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доп. элемент адреса"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уточнение"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дом"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос в техническую поддержку"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["от"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чат"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить сообщение"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете загрузить один или несколько файлов. Максимальный размер файла - 10Мб."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст сообщения"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создание виджета для"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата в"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма платежа"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия при успехе"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действия при неуспехе"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Генерация кода / ссылки для оплаты"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ операции магазина"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительный параметр"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибочный URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL уведомления"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куда вернется клиент после успешной оплаты"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куда клиент вернется после ошибки"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL, куда будет отправлено уведомление о результатах платежа"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зафиксировать сумму"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на оплату"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Документация"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок жизни счета на оплату в минутах, по умолчанию 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время действия ссылки"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виджет"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если не фиксировать сумму, то плательщик сможет ввести ее самостоятельно (подходит, например, для благотворительных платежей)"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать магазин"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы это видите, форма не была получена (но вы не должны это видеть)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крипто-актив"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет - источник покрытия"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Счет списания комиссии"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная эмиссия"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий размер эмиссии"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОКЕН"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покрытие"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассчет покрытия"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Привязка: "])}
        },
        "Warning": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К списанию"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инфо"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К зачислению"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно рассчитать сумму"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать файл"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допустимые форматы:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неизвестный формат"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком большой размер файла, максимальный размер: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите или перетащите сюда файлы для загрузки"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы загружены"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файлов"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результат:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить статус"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полезные ссылки"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Блокчейн:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другие настройки безопасности"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется подтверждение"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацию необходимо подтвердить одноразовым ключом"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизацию необходимо подтвердить волшебным словом"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрацию необходимо подтвердить кодом из смс-сообщения"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строка от"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["символов"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недопустимое значение"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажмите для выбора отображаемых столбцов"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доброе утро"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добрый день"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добрый вечер"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доброй ночи"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показано:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["всего"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На странице"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить платеж"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассчитать комиссию за возврат"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть платеж"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хеш в блокчейне"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML-риск"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К зачислению"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы согласны с измененными условиями, подтвердите зачисление:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если эти условия вам не подходят, вы можете вернуть средства на счет отправителя за вычетом расходов на возврат:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["на сумму"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["имеет AML-риск"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поэтому за ее зачисление пересчитана комиссия"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["таким образом, сумма к зачислению составляет"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новых поступлений не обнаружено"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежа"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])}
      }
    }
  }
}