<template>
    <div>
    <div class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input">
        <div class="col-sm-3 p-0 mt-0 d-flex flex-row align-items-center justify-content-between">
            <label class="input-label">
                {{ $t('components.service.Comiss.1') }}
            </label>
            <label class="input-label pr-1" :id="'comiss-1-'+this.id" v-if="this.$store.state.resize.w < 576">
                {{this.curr}}
            </label>
        </div>
        <div class="col-sm-3 input-wrapper loader-r-cc p-0 mt-0">
            <loading :active="this.loader"
                 :is-full-page="false"
                 :height='20'
                 :width='50'
                 :loader='"dots"'
                 :color='"#008D3E"'
                 :opacity='0'
                 />
            <InputSimple :disabled='true' id='commis_total' :dataValue='this.total'/>
        </div>
        <div v-if="this.$store.state.resize.w > 575" :id="'comiss-2-'+this.id" class="col-sm-1 input-wrapper p-0 pl-1 mt-0">
            {{this.curr}}
        </div>
        <div class="col-sm-3 input-wrapper p-0 pl-2 text-end" :class="{'mt-3': this.$store.state.resize.w < 576, 'mt-0': this.$store.state.resize.w > 575}">
            <label class="col-12 pr-1 input-label">{{ $t('components.service.Comiss.2') }}</label>
        </div>
        <div v-if="this.button" class="col-sm-2 input-wrapper p-0 mt-0">
            <Button class='col-12' :id="'comiss-3-'+this.id" :name="$t('components.service.Comiss.3')" type='btn-outline-warning' v-on:click='this.info = !this.info'/>
        </div>
        <div v-else class="col-sm-2 input-wrapper loader-r-cc p-0 mt-0">
            <loading :active="this.loader"
                 :is-full-page="false"
                 :height='20'
                 :width='50'
                 :loader='"dots"'
                 :color='"#008D3E"'
                 :opacity='0'
                 />
            <InputSimple :disabled='true' id='commis_f' :dataValue='this.comiss'/>
        </div>
    </div>
    <div v-if="this.enrollment" class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center input">
        <div class="col-sm-3 p-0 mt-0">
            <label class="input-label">{{ $t('components.service.Comiss.4') }}</label>
        </div>
        <div class="col-sm-3 input-wrapper loader-r-cc p-0 mt-0">
            <loading :active="this.loader"
                 :is-full-page="false"
                 :height='20'
                 :width='50'
                 :loader='"dots"'
                 :color='"#008D3E"'
                 :opacity='0'
                 />
            <InputSimple :disabled='true' id='commis_total' :dataValue='this.to_enrollment'/>
        </div>
        <div class="col-sm-1 input-wrapper p-0 pl-1 mt-0" :id="'comiss-4-'+this.id">
            {{this.curr_to}}
        </div>
        <div class="col-sm-3 input-wrapper p-0 pl-2 text-end mt-0">

        </div>
    </div>
    <div v-if="this.info" class="row container-fluid mr-0 ml-0 mt-3 p-0 g-3 align-items-center">
        <div class="col-sm-3 p-0 mt-0">

        </div>
        <div class="table-responsive col-sm-9 p-0 m-0">
            <table class="table table-borderless neue-roman m-0">
                <tbody class=''>
                    <tr v-for="(row, index) in this.infoData" :key="index">
                        <td class="p-0 d-flex flex-row align-items-center justify-content-start">
                            <span :id="'comiss-i-1-'+this.id+'-'+index">{{`${row.type_name}: ${row.value} ${row.curr_text}`}}</span>
                            <span :id="'comiss-i-1-'+this.id+'-'+index" v-if="row.comment" class="fst-italic ml-1 fs_09">({{row.comment}})</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
</template>

<script>
import InputSimple from '@/components/service/InputSimple';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Button from '@/components/service/Button';

export default {
    components: {
        InputSimple,
        Loading,
        Button
    },
    data() {
        return {
            timeout: false,
            comiss: '',
            total: '',
            to_enrollment: '',
            loader: false, 
            button: false,
            info: false,
        }
    },
    props: {
        sum: {
            type: [Number, String, Boolean]
        },
        id: {
            default: 'def_comiss',
            type: [String, Boolean]
        },
        curr: [String],
        curr_to: [String],
        precision: [String],
        precision_from: [String, Boolean],
        enrollment: [Boolean],
        cmd_name: [String, Boolean],
        params: [Object, Boolean],
        update: [Boolean],
        onMountStart: [Boolean],
    },
    mounted() {
        if (this.onMountStart && this.sum) {
            this.getCommiss(this.sum);
        }
    },
    watch: {
        sum(newS, oldS) {
            if (this.timeout) {
                window.clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.getCommiss(newS)
            }, 1000);
        },
        update(NewU, oldU) {
            if (NewU == true && this.sum) {
                if (this.timeout) {
                    window.clearTimeout(this.timeout);
                }

                this.timeout = setTimeout(() => {
                    this.getCommiss(this.sum)
                }, 1000);
            }
        }
    },
    methods: {
        outputValue(val) {
            if (val) {
                // this.total = String((Number(this.sum) + Number(this.comiss)).toFixed(Number(this.precision))).replace(/0*$/,"");
                // if (this.total[this.total.length - 1] == '.') {
                //     this.total += '00';
                // }
                return String(Math.abs(Number(val.change)));
            } else {
                return '';
            } 
        },
        getRes(arr) { 
            let res = 0
            arr.forEach(e => {
                res += Number(e.value);
            });
            return String(res);
        },
        getCommiss(newS) {
            this.$emit('updateParentState', false)
            this.$emit('onSuccess', false);
                if (newS && newS[newS.length - 1] != '.') {
                    this.button = false;
                    this.infoData = [];
                    this.loader = true;
                    this.comiss = false;
                    this.total = false;
                    this.to_enrollment = false;
                    this.axios.post('GetComiss', {no_loader: true, data: {
                        cmd_params: this.params,
                        cmd_name: this.cmd_name,
                    }}).then(res => {
                            this.loader = false;
                            if (res.data.body.result != 0) {
                                this.$emit('updateParentState', res.data.body.result_text)
                            } else {
                                if (res.data.body.tables[0].colvalues.length > 1) {
                                    this.button = true;
                                    this.infoData = res.data.body.tables[0].colvalues;
                                }
                                this.comiss = this.getRes(res.data.body.table.colvalues);
                                if (res.data.body.tables[1].colvalues.length == 1) {
                                    this.total = this.outputValue(res.data.body.tables[1].colvalues[0]);
                                    this.to_enrollment = true;
                                } else if (res.data.body.tables[1].colvalues.length > 1) {
                                    if (Number(res.data.body.tables[1].colvalues[0].change) < 0) {
                                        this.total = this.outputValue(res.data.body.tables[1].colvalues[0]);
                                        this.to_enrollment = this.outputValue(res.data.body.tables[1].colvalues[1]);
                                    } else {
                                        this.total = this.outputValue(res.data.body.tables[1].colvalues[1]);
                                        this.to_enrollment = this.outputValue(res.data.body.tables[1].colvalues[0]);
                                    }
                                }
                                if (!this.total || !this.to_enrollment) {
                                    this.$emit('updateParentState', this.$t('components.service.Comiss.5'));
                                    return;
                                }

                                this.$emit('updateParentState', false)
                                this.$emit('onSuccess', true);
                            } 
                    })
                } else {
                    this.comiss = '';
                    this.total = this.outputValue(false);
                }
        }
    },

}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}
.mt-1 {
    margin: 0 !important;
}
.require {
    color: red;
}

.vld-overlay {
    justify-content: flex-start;
    height: 20px;
    position: absolute;
    top: 23%;
    left: 10%;
    bottom: 28%;
}
.loader-r-cc {
    min-height: 20px;
    position: relative;
}

</style>