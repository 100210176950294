<template>
    <div class="row container-fluid mr-0 ml-0 mt-3 g-3 p-0 align-items-center input" :class="{'mt-0': this.first}">
        <div class="col-sm-3 p-0 mt-0">
            <label :for="id" class="col-form-label input-label pt-o pb-o">{{this.label}}<span class='require' v-if="this.require"> *</span></label>
        </div>
        <div class="col-sm-9 p-0 input-wrapper mt-0">
            <SwitchSimple :id="id" :dataValue='this.dataValue' :disabled="this.disabled" :type="this.type" @updateParentState="this.setValue"/>
            <div class="d-flex flex-row align-items-center justify-content-end">
                <Tooltip v-if="this.hint" :content='this.hint' />
                <div v-else-if="this.unvisible" class="mw34"></div>
                <Tooltip 
                    v-if="this.verification"
                    :custom="'item-i check'"
                    :content="$t('views.InfoReg.3')"
                />
                <div v-else-if="this.unvisible || this.vMark" class="mw34"></div>
                <Tooltip v-if="this.unvisible" :content="$t('views.InfoReg.4')" :htmlContent="true">
                    <SwitchSimple :id="id" :dataValue='this.unvisibleValue' :disabled="this.is_public == '2' ? true : false" :type="'sm'" class="ml-1" @updateParentState="this.setUnvisibleValue"/>
                </Tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import SwitchSimple from '@/components/service/SwitchSimple';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Tooltip,
        SwitchSimple,
    },
    data() {
        return {
            value: this.dataValue ? true : false,
            unvisibleValue: this.unvisibleDataValue ? this.unvisibleDataValue : '',
        }
    },
    props: {
        id: [String, Boolean],
        label: [String],
        hint: [String],
        verification: [String, Boolean],
        first: {
            type: Boolean,
            default: false,
        },
        type: {
            default: 'form-switch-xl',
            type: [String]
        }, 
        require: [Boolean],
        disabled: [String, Boolean],
        dataValue: [String, Boolean],
        unvisible: [Boolean],
        unvisibleDataValue: [String, Boolean], 
        vMark: [Boolean],
        is_public: [Boolean, String]
    },
    methods: {
        setValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                value: arg.value
            })
        },
        setUnvisibleValue(arg) {
            this.$emit('updateParentState', {
                id: arg.id,
                unvisible: true,
                value: arg.value
            })
        },
    },

}
</script>

<style lang="scss" scoped>
.input {
    font-family: 'HelveticaNeue roman';
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
    }
}
.mt-1 {
    margin: 0 !important;
}
.require {
    color: red;
}
</style>