<template>
    <div>
        <div class='d-flex justify-content-between neue mb-2'>
            <h4>{{ this.content.proffer.external_type_text ?  this.content.proffer.external_type_text : `${this.$t('views.p2p.Edit.1')} ${this.content.proffer.code}`}}</h4>
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="mb-3 neue-roman">
            <Input type='textarea' :dataValue='this.data.external_note' :label="$t('views.p2p.Edit.2')" id='external_note' @updateParentState="this.setValue"/>
            <Input type='textarea' :dataValue='this.data.external_requisites' :label="$t('views.p2p.Edit.3')" id='external_requisites' @updateParentState="this.setValue"/>
            <div  class='container-fluid d-flex align-items-center p-0 mt-3 g-3'>
                <div class='col-3'>
                    <span>{{ $t('views.Stock.CurrTransfer.10') }}<span class='require'> *</span></span>
                </div>
                <div class='col-4'>
                    <InputSimple
                        :id="'rate_external_count'" 
                        :dataValue="this.data.rate_external_count" 
                        @updateParentState="this.setValue"
                    />
                </div>
                <div  class='col-1 d-flex p-0 g-3 justify-content-around'>
                    <i class='item-i c28' :class="'equal'"></i>
                </div>
                <div class='col-4'>
                    <InputSimple
                        :id="'rate_internal_count'"
                        :dataValue="this.data.rate_internal_count" 
                        @updateParentState="this.setValue"
                    />
                </div>
            </div>
            <div class='container-fluid d-flex align-items-center pb-1 g-3 mt-3'>
                <div class='col-3'></div>
                <div class='col-4 fs_08 p-0 d-flex flex-row align-items-center justify-content-between'>
                    <span>{{$t('views.p2p.Edit.4')}}</span>
                </div>
                <div class='col-1'></div>
                <div class='col-4 fs_08 p-0 d-flex flex-row align-items-center justify-content-between'>
                    <span class="ml-1">{{$t('views.p2p.Edit.5')}}</span>
                </div>
            </div>
            <div  class='container-fluid d-flex align-items-center p-0 g-3'>
                <div class='col-3'>
                    <span>{{$t('views.p2p.Edit.6')}}<span class='require'> *</span></span>
                </div>
                <div class='col-4'>
                    <InputSimple 
                        :id="'internal_min'"
                        :invalid="this.checkedMinMaxValue"
                        :invalidHint="this.$t('views.P2P.New.41')"
                        :dataValue="this.data.internal_min"
                        @updateParentState="this.setValue"
                    />
                </div>
                <div  class='col-1 d-flex p-0 g-3 justify-content-around'>

                </div>
                <div class='col-4'>
                    <InputSimple
                        :id="'internal_max'"
                        :invalid="this.checkedMinMaxValue"
                        :dataValue="this.data.internal_max"
                        @updateParentState="this.setValue"
                    />
                </div>
            </div>
            <Input :dataValue='this.data.timeout' :label="$t('views.p2p.Edit.7')" id='timeout' @updateParentState="this.setValue" :require="true"/>
            <div class="d-flex flex-column flex-md-row mt-3 align-items-center justify-content-around input-wrapper p-0">
                <SwitchSimple class='pl-4 pt-0 fs_09' :label="$t('views.p2p.Edit.8')" :dataValue="this.data.active" type='form-switch-md' id='active' @updateParentState="this.setValue"/>
                <SwitchSimple class='pl-4 pt-0 fs_09' :label="$t('views.p2p.Edit.9')" type='form-switch-md' :dataValue="this.data.only_by_link" id='only_by_link' @updateParentState="this.setValue"/>
                <SwitchSimple class='pl-4 pt-0 fs_09' :label="$t('views.p2p.Edit.10')" :dataValue="this.data.only_for_verified" type='form-switch-md' id='only_for_verified' @updateParentState="this.setValue"/>
            </div>
        </div>

        <div class="col-12 col-md-4 mt-4 m-auto d-flex flex-column flex-md-row align-items-center justify-content-between">
            <Button :name="$t('views.p2p.Edit.11')" class="col-12 col-sm-6" :class="this.$store.state.resize.w > 768 ? 'mr-1' : 'mb-2'" type='btn-outline-warning' v-on:click="this.close"/>
            <Button :name="$t('views.p2p.Edit.12')" class="col-12 col-sm-6" type='btn-warning' v-on:click="this.editProffer" :disabled="this.disabledSave"/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button'; 
import Select from '@/components/service/Select';
import Input from '@/components/service/Input';
import Switch from '@/components/service/Switch';
import InputSimple from '@/components/service/InputSimple';
import SwitchSimple from '@/components/service/SwitchSimple';

export default {
    components: {
        Button,
        Select,
        Input,
        Switch,
        InputSimple,
        SwitchSimple,
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            data: {
                active: this.$store.state.modal.content.proffer.active,
                code: this.$store.state.modal.content.proffer.code,
                internal_direction: this.$store.state.modal.content.proffer.code,
                external_note: this.$store.state.modal.content.proffer.external_note,
                external_requisites: this.$store.state.modal.content.proffer.external_requisites,
                internal_max: this.$store.state.modal.content.proffer.internal_max,
                internal_min: this.$store.state.modal.content.proffer.internal_min,
                only_by_link: this.$store.state.modal.content.proffer.only_by_link,
                only_for_verified: this.$store.state.modal.content.proffer.only_for_verified,
                rate_external_count: this.$store.state.modal.content.proffer.rate_external_count,
                rate_internal_count: this.$store.state.modal.content.proffer.rate_internal_count,
                timeout: this.$store.state.modal.content.proffer.timeout,
            },
            checkedMinMaxValue: false,
            disabledSave: true,
        }
    },
    mounted() {
        console.log(this.content);

        this.disabledSave = !this.checkRequiredFields();
    },
    methods: {
        setValue(arg) {
            this.data[arg.id] = arg.value;

            // лимит введен
            if (arg.id == 'internal_min' || arg.id == 'internal_max') {
                this.checkedMinMaxValue = this.isMinLargeMax();
            }
            this.disabledSave = !this.checkRequiredFields();
        },
        checkRequiredFields() {
            if (this.data.rate_external_count != '' &&
                this.data.rate_internal_count != '' &&
                this.data.internal_min != '' &&
                this.data.internal_max != '' &&
                this.data.timeout != ''
            ) {
                return true;
            }

            return false;
        },
        editProffer() {
            console.log(this.data);
            delete this.data.internal_direction;
            this.axios.post('SetparamsP2PProffer', {data: this.data}).then(res => {
                this.content.updateData();
            });
        },
        isMinLargeMax() {
            if (this.data.internal_min != '' && this.data.internal_max != '') {
                // приводим строки к числам, не можем использовать Number т.к. Number - это компонент
                return Number(this.data.internal_min) > Number(this.data.internal_max);
            }

            return false;
        },
    }
}
</script>

<style lang="scss">
    .not-validate {
        border: 1px solid #FF7F11;
        border-radius: 0.375rem;
    }

    .not-validate:focus {
        border: 1px solid #FF7F11;
        border-radius: 0.375rem;
    }
</style>
