<template>
    <Popper
        v-if="this.htmlContent"
        arrow
        :content="this.content"
        :hover="this.hover"
        :placement="this.placement"
    >
        <slot></slot>
    </Popper>
    <Popper
        v-else-if="this.custom"
        arrow
        :content="this.content"
        :hover="this.hover"
        :placement="this.placement"
    >
        <i :class="this.custom"></i>
    </Popper>
    <Popper
        v-else-if="this.small_icon"
        @open:popper="() => this.iconActive = true"
        @close:popper="() => this.iconActive = false"
        arrow
        :content="this.content"
        :hover="this.hover"
        :placement="this.placement"
    >
        <Icon :active="this.iconActive" name="question" size="c22" />
    </Popper>
    <Popper
        v-else
        @open:popper="() => this.iconActive = true"
        @close:popper="() => this.iconActive = false"
        arrow
        :content="this.content"
        :hover="this.hover"
        :placement="this.placement"
    >
        <Icon :active="this.iconActive" name="question" size="c34 ml-1" />
    </Popper>
</template>

<script>
import Popper from "vue3-popper";
import Icon from "@/components/icons/Icon.vue";
  export default {
    components: {
      Popper,
      Icon
    },
    props: {
        content: { 
          type: [String, Boolean],
          default: 'Some hint',
        },
        hover: {
          type: Boolean,
          default: true,
        },
        placement: {
          type: String,
          default: 'top',
        },
        custom: [String, Boolean],
        small_icon: {
          default: false,
          type: [Boolean],
        },
        htmlContent: {
          default: false,
          type: [Boolean]
        }
    },
    data() {
      return {
        iconActive: false,
      }
    },
  };
</script>

<style lang="scss">
    :root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 2px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-border-color: #333333;
    --popper-theme-padding: 0.5rem;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }

  .popper {
    max-width: 15rem;
    text-align: center;
    font-size: 16px;
    font-weight: normal;

    & #arrow {
      left: -4px!important;
    }
  }
</style>