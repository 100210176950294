<template>
    <div>
        <div class=''>
            <h4 id="c-m-pp-3">{{this.status}}</h4>
        </div>
        <div class=''>
            <Input type='password' :label="$t('components.service.modal.PayPass.1')"
             autofocus 
             :autocomplete="'off'" 
             id='pay_pass'
             @updateParentState="this.updateState" hint=''
             @onEnter="this.submit"/>
        </div>
        <div class='d-flex mx-auto justify-content-center pt-3'>
            <Button id="c-m-pp-1" :name="$t('components.service.modal.PayPass.2')" class='mr-1' type='btn-outline-warning' v-on:click='this.close'/>
            <Button id="c-m-pp-2" :name="$t('components.service.modal.PayPass.3')" :disabled="!this.pass" type='btn-warning' v-on:click='this.submit'/>
        </div>
    </div>
</template>

<script>
import Button from '@/components/service/Button';
import Input from '@/components/service/Input';

export default {
    components: {
        Button,
        Input
    },
    props: {
        close: [Function],
    },
    data () {
        return {
            status: this.$t('components.service.modal.PayPass.4'),
            pass: false, 
        }
    },
    methods: {
        updateState(state) {
            this.pass = state.value;
        },
        submit() {
            this.$store.commit('PAY_PASS', this.pass);
            let callback = this.$store.state.modal.content.callback;
            this.$store.commit('MODAL', {
                        action: false,
                        type: false,
                        content: false
                    })
            this.$store.commit('DARK_WRAPPER', false);
            callback();
        }
    },
}
</script>