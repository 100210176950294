<template>
    <div>
        <div v-if='this.indexes || this.head_slot' class="d-flex container-fluid p-0 align-items-center justify-content-end  flex-column flex-lg-row"
                :class="{'justify-content-between': this.head_slot, 'justify-content-end': !this.head_slot}"
            >
                <div v-if="this.head_slot" class="col-12 col-lg-6 mb-1">
                    <slot name="head"></slot>
                </div>
                <div v-if='this.indexes' class="d-flex align-items-center flex-row col-12 col-lg-4 mb-1"
                    :class="{'col-sm-8 col-md-6': !this.head_slot}"
                >
                    <span class="mr-1">{{ $t('components.service.TableList.1') }}</span>
                    <InputSimple :id="'search_string'" :dataValue="this.search_string" @updateParentState="this.setValue"/>
                </div>
        </div>
        <div class='px-4 py-2 content'>
            <slot name="table"></slot>
            <div v-if="this.btnChoiseColumns || this.btnFullTable" class="d-flex justify-content-end align-items-center mb-3">
                <div v-if="this.btnChoiseColumns">
                    <Tooltip :content="$t('components.service.TableList.2')" :htmlContent="true">
                        <i class="item-i table-icon c24 pointer mr-1" @click="this.choiseColumns()"></i>
                    </Tooltip>
                </div>
                <div v-if="this.btnFullTable">
                    <Tooltip :content="$t('views.ATM.2')" :htmlContent="true">
                        <i class="item-i full-table c24 pointer" @click="this.toggleContainer()"></i>
                    </Tooltip>
                </div>
            </div>
        </div>
        <slot name="buttons"></slot>
        <div class="d-flex container-fluid g-3 align-items-center justify-content-between col-12 p-0">
            <Pagination :data.sync='this.d' :max="this.max" :savePage="this.savePage" @updateContentState="this.updateContent"/>
        </div>
    </div>
</template>
 
<script>
import Pagination from '@/components/service/pagination/Pagination';
import InputSimple from '@/components/service/InputSimple';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Pagination,
        InputSimple,
        Tooltip
    },
    props: {
        data: [Array],
        max: {
            type: [Number, Boolean],
            default: 6,
        },
        savePage: {
            type: Boolean,
        },
        paginationClass: {
            type: [String, Boolean],
        },
        sortRule: {
            type: [Object, Boolean],
        },
        indexes: [Array, Boolean],
        colNames: [Array, Boolean],
        initialData: [Array, Boolean],
        disabledColumns: [Array, Boolean],
        localStorageName: [String, Boolean],
        btnFullTable: [Boolean],
        btnChoiseColumns: [Boolean],
        colSubs: [Array, Boolean],
        head_slot: [Boolean],
    },
    watch: {
        data: {
            handler: function(newData, oldData) {
                // console.log(newData);
                this.d = this.$lib.sortArr(newData.map(item => item), this.sortRule);
            },
            deep: true,
        },
    },
    data() {
        return {
            d: this.$lib.sortArr(this.data.map(item => item), this.sortRule),
            search_string: ''
        }
    },
    created() {
        // console.log('created indexes', this.indexes);
    },
    methods: {
        toggleContainer() {
            this.$emit('toggleContainer');
        },
        updateContent(arg) {
            this.$emit('updateContentState', arg);
        },
        setValue(arg) {
            this[arg.id] = arg.value;
            if (this.indexes && arg.value != '') {
                let ar = [];
                this.data.forEach(e => {
                    for(let i = 0; i < this.indexes.length; i++) {
                        let reg = new RegExp(`${arg.value.toLowerCase()}`, 'i');
                        if (reg.test(e[this.indexes[i]].toLowerCase())) {
                            ar.push(e);
                            i = this.indexes.length;
                        }
                    }
                })
                this.d = this.$lib.sortArr(ar, this.sortRule);
            } else {
                this.d = this.$lib.sortArr(this.data.map(item => item), this.sortRule);
            }
            
        },
        dSort(ind, type) {
            if (this.sortRule.ind === ind ) {
                this.sortRule.to = !this.sortRule.to;
            } else {
                this.sortRule.ind = ind;
                this.sortRule.to = true;
                this.sortRule.type = type;
            }

            this.d = this.$lib.sortArr(this.d, this.sortRule);
        },
        choiseColumns() {
            this.$store.commit('MODAL', {
                    action: true,
                    type: 'ChoiseColumns',
                    content: {
                        columns: this.colSubs ? this.colSubs : this.colNames,
                        data: this.initialData,
                        disabledColumns: this.disabledColumns,
                        updateColumns: (columns) => {
                            const colNamesFilter = columns.filter(item => item.value == true);
                            const filteredData = this.$lib.getFilteredData(columns, this.initialData);

                            window.localStorage.setItem(this.localStorageName, JSON.stringify(columns));

                            this.$emit('updateColumns', {
                                colNames: columns,
                                colSubs: columns,
                                colNamesFilter: colNamesFilter,
                                colSubsFilter: colNamesFilter,
                                filteredData: filteredData
                            });
                        }
                    },
                }
            );
        },
    }
}
</script>