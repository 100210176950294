<template>
    <div>
        <div class='d-flex justify-content-between neue align-items-center mb-2 pb-1'>
            <h4 class="mb-0">{{ $t('views.P2P.RequestP2POffer.1') }}</h4>
            <!--<h4 class="mb-0">{{ this.content.external_type_text ?  this.content.external_type_text : `${this.$t('views.P2P.RequestP2POffer.1')}${this.content.code}`}}</h4>!-->
            <i class='big item-i x_red pointer' v-on:click='this.close'></i>
        </div>
        <div class="block-info mb-3 p-3">
            <div class="d-flex flex-column flex-md-row justify-content-between mb-2">
                <div class="col-12 col-md-3">{{ $t('views.P2P.RequestP2POffer.2') }}</div>
                <div class="d-flex align-items-center col-9">
                    <div class="d-flex flex-column">
                        <div class="d-flex">
                            <span class="mr-1">{{ this.content.code }}</span>
                            <Tooltip
                                v-if="this.content.only_for_verified != '0'"
                                class="pointer mv-none" 
                                :content="$t('views.Stock.Offers.26')" 
                                :custom="'item-i pro-deal-check c24'"
                            />
                        </div>
                        <div class="fs_08">{{ this.content.external_note }}</div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-md-row justify-content-between align-items mb-2">
                <div class="col-12 col-md-3">{{ $t('views.P2P.RequestP2POffer.3') }}</div>
                <div class="d-flex col-9">
                    <div class="d-flex flex-column flex-md-row align-items-md-center col-12 col-md-7">
                        <span v-if="this.content.maker_verified != '0'" class="d-flex align-items-center">
                            <Tooltip
                                class="pointer mv-none" 
                                :content="$t('views.Stock.Offers.25')" 
                                :custom="'item-i check-maker-verification c18'"
                            />
                            &nbsp;
                        </span>
                        <span class="mr-1">
                            {{ this.content.maker_name ? this.content.maker_name : this.content.maker_uid }}
                        </span>
                        <span class="d-flex align-items-center mr-1">
                            <i v-if="this.content.maker_avg_rate" class="item-i star contain c16 position-relative" style="top: -2px;"></i> 
                            <span v-if="this.content.maker_avg_rate" class="ml-1">{{ this.content.maker_avg_rate  }}</span>
                        </span>
                        <span class="d-flex align-items-center grey">
                            <span class=""> {{ $t('views.Stock.Offers.17') }} </span>
                            <span class="ml-1 color-system-green"> {{ this.content.maker_swaps_success }} </span>
                            <span class="">/ </span>
                            <span class="color-acent"> {{ this.content.maker_swaps_fail }} </span>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column flex-md-row align-items-md-center mb-2">
                <div class="col-12 col-md-3">{{ $t('views.P2P.RequestP2POffer.4') }}</div>
                <div class="d-flex justify-content-between align-items-center">
                    <span>{{ this.content.timeout }} {{ $t('views.P2P.RequestP2POffer.14') }}</span>
                    <Tooltip
                        class="pointer mv-none" 
                        :content="$t('views.P2P.RequestP2POffer.5')"
                        :small_icon="true"
                    />
                </div>
            </div>
            <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                <div class="col-3">{{ $t('views.P2P.RequestP2POffer.6') }}</div>
                <div class="d-flex col-9 justify-content-between align-items-center">
                    <div v-html="this.getRate(this.$lib.ev(this, `${this.content.rate_external_count} / ${this.content.rate_internal_count}`), this.content.external_type_text, this.content.internal_curr_text)"></div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-2 ">
            <div class="col-12 col-md-3 mh-38 d-flex align-items-md-center">
                {{ $t('views.P2P.RequestP2POffer.7') }}
                <span class='require'> *</span>
            </div>
            <div class="d-flex flex-column flex-md-row col-12 col-md-9">
                <div class="col-12 col-md-2 mr-1">
                    <InputSimple
                        type='text'
                        :dataValue='this.content.internal_direction == "Taker2maker" ? 
                            this.data.amount : 
                            this.data.amount_to'
                        :regCheck='{f: (arg) => {
                            return this.content.internal_direction == "Taker2maker" ? 
                                this.checkAmount(arg, "amount") : 
                                this.checkAmount(arg, "amount_to");
                        }}'
                        :require="true" 
                        :invalid="this.content.internal_direction == 'Taker2maker' ? this.check_amount : false "
                        :invalidHint="this.content.internal_direction == 'Taker2maker' ? this.getTextValidation() : ''"
                        :id='this.content.internal_direction == "Taker2maker" ? 
                            "amount" : 
                            "amount_to"'
                        :placeholder="$t('views.P2P.RequestP2POffer.12')"
                        @updateParentState="this.setValue" 
                    />
                    <span v-if="this.content.internal_direction == 'Taker2maker'" class="fs_08">
                        {{ $math.format(parseFloat(this.content.internal_min), {notation: 'fixed'}) }} - {{ $math.format(parseFloat(this.content.internal_max), {notation: 'fixed'}) }}
                    </span>
                </div>
                <div class="col-12 col-md-2 mr-1 fs_08"
                    :class="{'mt-2': this.$store.state.resize.w <= 768 && this.content.internal_direction == 'Maker2taker'}"
                >
                    <InputSimple
                        type='text'
                        :dataValue='this.content.internal_direction == "Maker2taker" ? 
                            this.getExternalName() : 
                            this.content.internal_curr_text' 
                        id='curr_from'
                        :placeholder='this.content.internal_direction == "Maker2taker" ? 
                            this.getExternalName() : 
                            this.content.internal_curr_text'
                        disabled 
                    />
                </div>
                <div 
                    class="col-12 col-md-8"
                    :class="{'pr-2': this.$store.state.resize.w > 768, 
                        'mt-2': this.$store.state.resize.w <= 768 && this.content.internal_direction == 'Taker2maker'}"
                >
                    <SelectSimple
                        v-if="!this.isNoKeyts && this.content.internal_direction == 'Taker2maker'"
                        id='keyt'
                        :keyt_thin="true"
                        :hotUpdate="true"
                        :dataValue='this.data.keyt'
                        :defaultValue="$t('views.P2P.RequestP2POffer.11')"
                        :keywords.sync='this.keywords' 
                        @updateParentState="this.setValue"  
                    />
                    <div 
                        v-else-if="this.isNoKeyts && this.content.internal_direction == 'Taker2maker'" 
                        class="d-flex flex-column flex-md-row align-items-md-center"
                    >
                        <span class="mr-1">{{ $t('views.P2P.New.35') }}</span>
                        <div
                            @mouseenter="this.btnIconClass ='ext-link-white'"
                            @mouseleave="this.btnIconClass ='ext-link-orange'"
                        >
                            <Button 
                                :name="$t('views.P2P.RequestP2POffer.10')" 
                                type='btn-outline-warning' 
                                v-on:click='this.goToNewKeyt()'
                            >
                                <i class='ml-1 item-i c20' :class="this.btnIconClass"></i>
                            </Button>
                        </div>
                    </div>
                    <div v-if="this.content.internal_direction == 'Maker2taker'" class="external-curr-text">{{ this.content.external_type_text }}</div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column flex-md-row justify-content-between mb-2" 
            :class="this.content.internal_direction == 'Maker2taker' && this.$store.state.resize.w > 768 ? 'mt-4' : ''"
        >
            <div class="col-12 col-md-3 mh-38 d-flex align-items-md-center">
                {{ $t('views.P2P.RequestP2POffer.8') }}
                <span class='require'> *</span>
            </div>
            <div class="d-flex flex-column flex-md-row col-12 col-md-9">
                <div 
                    class="col-12 col-md-2 mr-1"
                    :class="this.$store.state.resize.w <= 768 ? 'mb-1' : ''"
                >
                    <InputSimple
                        type='text'
                        :dataValue='this.content.internal_direction == "Maker2taker" ? 
                            this.data.amount : 
                            this.data.amount_to'
                        :regCheck='{f: (arg) => {
                            return this.content.internal_direction == "Maker2taker" ? 
                                this.checkAmount(arg, "amount") : 
                                this.checkAmount(arg, "amount_to");
                        }}'
                        :require="true"
                        :invalid="this.content.internal_direction == 'Maker2taker' ? this.check_amount : false"
                        :invalidHint="this.content.internal_direction == 'Maker2taker' ? this.getTextValidation() : ''"
                        :id='this.content.internal_direction == "Maker2taker" ? 
                            "amount" : 
                            "amount_to"'
                        :placeholder="$t('views.P2P.RequestP2POffer.12')"
                        @updateParentState="this.setValue" 
                    />
                    <span v-if="this.content.internal_direction == 'Maker2taker'" class="fs_08">
                        {{ $math.format(parseFloat(this.content.internal_min), {notation: 'fixed'}) }} - {{ $math.format(parseFloat(this.content.internal_max), {notation: 'fixed'}) }}
                    </span>
                </div>
                <div 
                    class="col-12 col-md-2 mr-1 fs_08"
                    :class="this.$store.state.resize.w <= 768 ? 'mb-1' : ''"
                >
                    <InputSimple
                        type='text'
                        :dataValue='this.content.internal_direction == "Maker2taker" ? 
                            this.content.internal_curr_text : 
                            this.getExternalName()' 
                        id='curr_to'
                        :placeholder='this.content.internal_direction == "Maker2taker" ? 
                            this.content.internal_curr_text : 
                            this.getExternalName()'
                        disabled 
                    />
                </div>
                <div
                    class="col-12 col-md-8"
                    :class="{'pr-2': this.$store.state.resize.w > 768, 'mt-1': !this.$store.state.resize.w > 768}"
                >
                    <SelectSimple
                        v-if="!this.isNoKeyts && this.content.internal_direction == 'Maker2taker'"
                        id='keyt'
                        :keyt_thin="true"
                        :hotUpdate="true"
                        :dataValue='this.data.keyt'
                        :defaultValue="$t('views.P2P.RequestP2POffer.11')"
                        :keywords.sync='this.keywords' 
                        @updateParentState="this.setValue"  
                    />
                    <div v-else-if="this.isNoKeyts && this.content.internal_direction == 'Maker2taker'" class="d-flex flex-column flex-md-row align-items-md-center">
                        <span class="mr-1">{{ $t('views.P2P.New.35') }}</span>
                        <div
                            @mouseenter="this.btnIconClass ='ext-link-white'"
                            @mouseleave="this.btnIconClass ='ext-link-orange'"
                        >
                            <Button 
                                :name="$t('views.P2P.RequestP2POffer.10')" 
                                type='btn-outline-warning' 
                                v-on:click='this.goToNewKeyt()'
                            >
                                <i class='ml-1 item-i c20' :class="this.btnIconClass"></i>
                            </Button>
                        </div>
                    </div>
                    <div v-else-if="this.content.internal_direction == 'Taker2maker'" class="external-curr-text">{{ this.content.external_type_text }}</div>
                </div>
            </div>
        </div>
        <div class="buttons-container mt-4 p-0 m-auto d-flex align-items-center">
            <Button 
                :name="$t('views.P2P.RequestP2POffer.9')" 
                class="col-12 col-sm-3" 
                type='btn-warning' 
                v-on:click="this.requestP2PSwap"
                :disabled="this.isNoKeyts || this.isBtnSendDisable()"
            />
        </div>
        <div class="mt-4 color-invalid" v-if="this.errorText">{{ this.errorText }}</div>
    </div>
</template>

<script>
import Button from '@/components/service/Button'; 
import Select from '@/components/service/Select';
import SelectSimple from '@/components/service/SelectSimple';
import Input from '@/components/service/Input';
import Switch from '@/components/service/Switch';
import InputSimple from '@/components/service/InputSimple';
import SwitchSimple from '@/components/service/SwitchSimple';
import Tooltip from '@/components/service/Tooltip';

export default {
    components: {
        Button,
        Select,
        SelectSimple,
        Input,
        Switch,
        InputSimple,
        SwitchSimple,
        Tooltip
    },
    props: {
        close: [Function],
    }, 
    data () {
        return { 
            content: this.$store.state.modal.content,
            keywords: [],
            data: {
                amount: '',
                amount_to: '',
                keyt: false,
                proffer: this.$store.state.modal.content.code
            },
            isNoKeyts: false,
            prec_rate: 4,
            curr_list: [],
            external_curr_prec: '1',
            internal_curr_prec: '1',
            btnIconClass: 'ext-link-orange',
            check_amount: false,
            errorText: ''
        }
    },
    computed: {
        t () {
            let k = this.$store.getters.get_keyts_keywords;
            if (k.length > 0) {
                this.getKeyts(k);
            }
            return k;
        }
    },
    watch: {
        t: {
            handler: function(newData, oldData) {
                // console.log(newData)
                this.getKeyts(newData);
            },
            deep: true,
        }
    },
    methods: {
        getTextValidation() {
            return `${this.$t('views.P2P.RequestP2POffer.13')} ${this.$math.format(parseFloat(this.content.internal_min))}  -  ${this.$math.format(parseFloat(this.content.internal_max))}`
        },
        checkAmountValue() {
            return Number(this.data.amount) < Number(this.content.internal_min) || Number(this.data.amount) > Number(this.content.internal_max);
        },
        getCurrsPrec() {
            let ext_curr, int_curr;
            this.axios.post('GetListCurr').then(res => {
                let l = res.data.body;

                if(l.length != 0) {
                    l.forEach(e => {
                        e['value'] = e.full_name;         
                    });
                    this.curr_list = l;

                    if (this.curr_list.length > 0) {
                        if (this.content.external_curr == '0' && this.content.external_type_unit_precision != '0') {
                            this.external_curr_prec = this.content.external_type_unit_precision;
                        } else {
                            ext_curr = this.curr_list.find(curr => curr.code == this.content.external_curr);
                            this.external_curr_prec = ext_curr ? ext_curr.precision : '2';
                        }

                        int_curr = this.curr_list.find(curr => curr.code == this.content.internal_curr);
                        this.internal_curr_prec = int_curr ? int_curr.precision : '2';
                    }
                }

                console.log('this.external_curr_prec', this.external_curr_prec);
                console.log('this.internal_curr_prec', this.internal_curr_prec);
            })
        },
        checkAmount(arg, inputId) {
            let prec;

            if (inputId == 'amount' && this.content.internal_direction == 'Maker2taker') {
                prec = this.internal_curr_prec;
            }

            if (inputId == 'amount_to' && this.content.internal_direction == 'Maker2taker') {
                prec = this.external_curr_prec;
            }

            if (inputId == 'amount' && this.content.internal_direction == 'Taker2maker') {
                prec = this.internal_curr_prec;
            }

            if (inputId == 'amount_to' && this.content.internal_direction == 'Taker2maker') {
                prec = this.external_curr_prec;
            }

            console.log('prec', prec);

            arg = String(arg);
            arg = arg.replace(',', ".");
            let regExp = new RegExp(/[^0-9.]/g);
            let t = arg.replace(regExp, "");
            if (t === '') {
                t = '';
            } else if (t[t.length - 1] != '.') {
                let ind = t.indexOf('.');

                if (ind !== -1 && ind < t.length - prec) {
                    t = this.$math.format(this.$math.fix(t, prec), {notation: 'fixed'});
                }
            }
            arg = String(t);

            return arg;
        },
        calculateSumm(arg) {
            if (!Number(arg.value)) {
                this.data[arg.id] = 0;
                return;
            }

            const rate = this.$lib.ev(this, `${this.content.rate_external_count} / ${this.content.rate_internal_count}`);
            const rateСoefficient = this.getRateFrom(rate) == 1 ? this.getRateTo(rate) : this.getRateFrom(rate);
            console.log('internal dir', this.content.internal_direction);
            console.log('arg id', arg.id);

            if (arg.id == 'amount' && this.getRateFrom(rate) == 1) {
                this.data['amount'] = this.$lib.precRound(this, 
                    Number(arg.value), 
                    Number(this.internal_curr_prec)
                );
                this.data['amount_to'] = this.$lib.precRound(this, 
                    Number(arg.value) / Number(rateСoefficient), 
                    Number(this.external_curr_prec)
                );
            }

            if (arg.id == 'amount' && this.getRateTo(rate) == 1) {
                this.data['amount'] = this.$lib.precRound(this, 
                    Number(arg.value), 
                    Number(this.internal_curr_prec)
                );
                this.data['amount_to'] = this.$lib.precRound(this, 
                    Number(arg.value) * Number(rateСoefficient), 
                    Number(this.external_curr_prec)
                );
            }

            if (arg.id == 'amount_to' && this.getRateFrom(rate) == 1) {
                this.data['amount_to'] = this.$lib.precRound(this, 
                    Number(arg.value), 
                    Number(this.external_curr_prec)
                );
                this.data['amount'] = this.$lib.precRound(this, 
                    Number(arg.value) * Number(rateСoefficient), 
                    Number(this.internal_curr_prec)
                );
            }

            if (arg.id == 'amount_to' && this.getRateTo(rate) == 1) {
                this.data['amount_to'] = this.$lib.precRound(this, 
                    Number(arg.value), 
                    Number(this.external_curr_prec)
                );
                this.data['amount'] = this.$lib.precRound(this, 
                    Number(arg.value) / Number(rateСoefficient), 
                    Number(this.internal_curr_prec)
                );
            }

        },
        setValue(arg) {
            console.log('setValue');
            if (arg.id == 'amount' || arg.id == 'amount_to') {
                this.calculateSumm(arg);

                this.check_amount = this.data.amount ? this.checkAmountValue() : false;

                return;
            }

            this.data[arg.id] = arg.value;
        },
        getKeyts(k) {
            this.keywords = k.slice();

            this.keywords.forEach(keyt => {
                    keyt.value = keyt.code;
            });

            this.keywords = this.keywords.filter(keyt => keyt.curr == this.content.internal_curr_text);

            if (this.keywords.length == 0) {
                this.isNoKeyts = true;
            }

            if (this.keywords.length == 1) {
                this.data.keyt = this.keywords[0].value;
            }

        },
        getRateFrom(rate, revers = false) {
            let rate_from;
            if (Number(rate) >= 1) {
                rate_from = revers ? '1' : String(rate);
            } else {
                rate_from = revers ? this.$lib.ev(this, `1 / ${rate}`) : '1';
            }

            return this.$lib.precRound(this, rate_from, this.prec_rate);
        },
        getRateTo(rate, revers = false) {
            let rate_to;
            if (Number(rate) >= 1) {
                rate_to = revers ? String(rate) : '1';
            } else {
                rate_to = revers ? '1' : this.$lib.ev(this, `1 / ${rate}`);
            }

            return this.$lib.precRound(this, rate_to, this.prec_rate);
        },
        getRate(rate, from_text, to_text, revers = false) {
            return `<span>${this.$lib.precRound(this, this.getRateFrom(rate, revers), this.prec_rate)} <span>${from_text}</span> = ${this.$lib.precRound(this, this.getRateTo(rate, revers), this.prec_rate)} <span>${to_text}</span></span>`
        },
        requestP2PSwap() {
            let params = {
                amount: this.data.amount,
                keyt: this.data.keyt,
                proffer: this.data.proffer
            }
            this.axios.post('RequestP2PSwap', {data: params}).then(res => {
                if (res.data.body.result == '0') {
                    this.close();
                    this.$router.push({name: 'p2p_single_swap', query: {swap: res.data.body.advanced.swap}});
                } else {
                    this.errorText = res.data.body.result_text;
                }
            });
        },
        goToNewKeyt() {
            this.close();
            let url = '/keyts/new';
            url = this.data.curr ? url + '?curr=' + this.data.curr : url;
            window.open(url, '_blank');
        },
        isBtnSendDisable () { 
            return this.data.amount == '' || this.data.amount_to == '' || this.data.keyt == false;
        },
        getExternalName() {
            if (this.content.external_curr_text) {
                return this.content.external_curr_text;
            } else if (this.content.external_type_unit_abbreviation) {
                return this.content.external_type_unit_abbreviation;
            } else {
                return ''
            }
        }
    },

    mounted() {
        this.getCurrsPrec();
    }
}
</script>

<style lang="scss" scoped>
    .block-info {
        background: var(--bs-secondary-bg);
        border-radius: var(--bs-border-radius);
    }

    .external-curr-text {
        padding: 0.375rem 0.75rem;
    }

    .mh-38 {
        max-height: 38px;
    }
</style>