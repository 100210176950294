<template>
    <div ref='d_down' class="dropdown">
        <a v-if="this.is_slot" class="btn" :id="'c-dd-'+this.id" :class="this.type"  :style='this.style' v-on:click="this.open" role="button">
            <slot></slot>
        </a>
        <a v-else class="btn dropdown-toggle" :class="this.type" style="min-height: 38px;" :id="'c-dd-'+this.id"  :style='this.style' v-on:click="this.open" role="button">
            {{this.label}}
        </a> 

        <div v-if="this.active"  :style='"max-height: " + this.listHeight + "px; top: 2.17rem; " + this.opt_style' :class="[this.transformUp ? 'open-up' : '', this.position]" class="btn-group-vertical">
            <a v-for="(row, index) in this.menu" 
                v-on:mouseover="() => {this.active_btn = index;}"  
                v-on:mouseleave="() => {this.active_btn = false;}"
                :key="index" class="btn btn-outline-warning d-flex flex-row align-item-center justify-content-start" 
                :id="'c-dd-i-'+index" :style="this.opt_style" :class="{'disabled': row.disabled}" 
                v-on:click="(e) => {row.handler(this.args); this.close(e);}">
                <Icon v-if="row.icon" class="mr-1" :name="row.icon" :size="'c26'" :active="index === this.active_btn" />
                <span>{{row.name}}</span>
            </a>
        </div>
    </div>
</template> 

<script>
import { gsap } from "gsap"; 
import Icon from "@/components/icons/Icon.vue";

export default {
    components: {
        Icon
    },
    props: { 
        menu: [Array],
        label: {
            default: '',
            type: [String, Boolean]
        },
        is_slot: {
            default: false,
            type: [Boolean]
        },
        id: {
            default: 'def',
            type: [String, Boolean]
        },
        style: {
            default: '',
            type: [String, Boolean]
        },
        position: {
            default: '',
            type: [String, Boolean]
        },
        type: {
            default: 'btn-outline-warning',
            type: [String, Boolean]
        },
        opt_style: {
            default: '',
            type: [String, Boolean]
        },
        args: {
            default: false,
            type: [Object, Boolean]
        }
    },
    data() {
        return {
            active: false,
            transformUp: false,
            active_btn: false,
        }
    },
    mounted() {
    },
    methods: {
        open() {
            this.listHeight = this.getHeight();
            console.log(this.listHeight)
            this.transformUp = Number(this.listHeight) < 70
            if (this.transformUp) {
                this.listHeight = '200'
            }
            this.active = true;
            document.querySelector('body').addEventListener('mouseover', this.close);
        },
        close(e) {
            if (!this.$el.contains(e.target) || e.type == 'click') {
                this.active = false;
                document.querySelector('body').removeEventListener('mouseover', this.close);
            }
        },
        enter(el) {
            gsap.fromTo(el, {opacity: 0.1, height: '95%'}, {opacity: 1, height: 'auto', duration: 0.1});
        },
        leave(el, done) {
            gsap.fromTo(el, {opacity: 1}, {opacity: 0.1, duration: 0.2, onComplete: done});
        },
        getHeight() {
            let list = this.$refs.d_down.getBoundingClientRect();
            // let box = document.querySelector('#app').getBoundingClientRect();
            let box = {height: window.innerHeight};
            return String((box.height + window.pageYOffset) - (list.bottom + window.pageYOffset) - 10);
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-group-vertical {
    position: absolute;
    background-color: white;
    z-index: 400;
    min-width: min-content !important;
    border-radius: var(--bs-border-radius);
}
.btn {
    box-shadow: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.btn .item-i {
    height: 1.3em;
    width: 1.3em;
}
.btn-warning {
    color: white;
    background-color: $color-acent;
    border-color: $color-acent;
} 
.btn-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;

    filter: saturate(200%);
}
.disabled {
    filter: saturate(10%);
}
.btn-outline-warning {
    color: $color-acent;
    border-color: $color-acent;
}
.btn-outline-warning:focus {
    color: $color-acent;
    background-color: white;
}
.btn-outline-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: $color-acent;
}
.btn-success {
    color: white;
    background-color: $color-green;
    border-color: $color-green;
}
.btn-success:hover:not(.disabled) {
    color: white;
    background-color: $color-green;

    filter: saturate(150%);
}
// .btn-success:focus {
//     color: $color-green;
//     background-color: white;
// }

.btn-outline-success {
    color: $color-green;
    background-color: white;
    border-color: $color-green;
}
.btn-outline-success:hover:not(.disabled) {
    color: white;
    background-color: $color-green;
}
.btn-outline-success:focus {
    color: white;
    background-color: $color-green;
}

.btn-red {
    color: white;
    background-color: $color-warning;
}

.btn-outline-red {
    color: $color-warning;
    border-color: $color-warning;
}
.btn-outline-red:focus {
    color: $color-warning;
    background-color: white;
}
.btn-outline-red:hover:not(.disabled) {
    color: white;
    background-color: $color-warning;
    border-color: $color-warning;
}
.btn-href {
    color: white;
    background-color: $color-href;
    border-color: $color-href;
}
.btn-href:hover:not(.disabled) {
    color: white;
    background-color: $color-href;

    filter: saturate(150%);
}
.btn-outline-href {
    color: $color-href;
    border-color: $color-href;
}
.btn-outline-href:focus {
    color: $color-href;
    background-color: white;
}
.btn-outline-href:hover:not(.disabled) {
    color: white;
    background-color: $color-href;
    border-color: $color-href;
}

.btn-white-warning {
    color: white;
    background-color: $color-acent;
    border-color: white;
}
.btn-white-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: white;

    filter: saturate(150%);
}
.btn-outline-white-warning {
    color: $color-acent;
    background-color: white;
    border-color: $color-acent;
}
.btn-outline-white-warning:focus {
    color: white;
    background-color: $color-acent;
    border-color: white;
}
.btn-outline-white-warning:hover:not(.disabled) {
    color: white;
    background-color: $color-acent;
    border-color: white;
}

.btn-dashed, .btn-dashed:hover:not(.disabled)  {
    border: 2px dashed #d6d4d4;
    border-radius: 30px;
}

.btn-dashed-active, .btn-dashed-active:hover:not(.disabled)  {
    border: 2px solid $color-green;
    border-radius: 30px;
}
.disabled {
    filter: saturate(10%);
}
.lg-left {
    @media (min-width: 991px) {
        left: 1%;
    }
}
.right {
    right: 1%;
}
.open-up {
  transform: translateY(-117%);
  border-radius: 0.25rem;
  border-top: 1px solid #ced4da;
}
</style>