export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Español"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Eres nuevo aquí?"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación de contraseña"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono o correo electrónico:"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperación de contraseña"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo nombre:"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento:"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario encontrado"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período desde"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período hasta"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros/Comentario"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron operaciones"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de operaciones"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresos:"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" operaciones por un monto de"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos:"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de operaciones"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos y servicios"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias internas"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago de factura"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias entre tus cuentas"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de cheques criptográficos"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activación de cheques criptográficos"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso/gasto"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasto"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= igual"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ no igual"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> mayor"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ mayor o igual"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< menor"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ menor o igual"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado exitosamente"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de operaciones"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de cuenta"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no seleccionada"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio en la bolsa"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de dirección"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias dentro de tu billetera"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasaporte digital"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más detalles"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de Usuario:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de registro:"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última autorización:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis activos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todo"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargar"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuentas"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña de confirmación"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para confirmar las operaciones, debes crear una contraseña de confirmación."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos populares"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noticias"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraer"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay activos"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al usuario"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo global"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar saldo"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de registro"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmado"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Público"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el grupo"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupos de pagos"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del pago"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de transferencia"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantilla"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta a recargar"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Método de pago"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de cuenta"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de transferencia"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar transferencia"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre transferencias"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucción"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga de dirección"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección a recargar"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la lista de tareas"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de transferencia"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operación"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de operaciones"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cuentas"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error desconocido"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tokens"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de transferencia:"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto:"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario:"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de la transacción"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la protección:"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar como plantilla"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia a otro usuario"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de protección es un código que debe introducir el destinatario para completar la transacción. El destinatario verá la transferencia protegida inmediatamente en la sección \"Historial de operaciones\", pero no podrá usar el dinero hasta que ingrese el código de protección en la tarjeta de transacción. Si el plazo del pago expira, los fondos se devolverán al remitente."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de transferencia"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hacia"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferir"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia entre tus cuentas"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencias dentro de la billetera"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo salió mal"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva dirección"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nueva cuenta"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar seleccionados"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis activos"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cuentas"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la cuenta"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar las cuentas?"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la cuenta?"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron activos"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron cuentas"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar dirección"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar las direcciones?"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la dirección?"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No fiduciario"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fíat"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completar"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dirección"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cuenta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear dirección"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de nueva dirección"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de nueva cuenta"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de anti-phishing es un mecanismo adicional de protección cuando trabajas con la oficina."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase de saludo que configures se mostrará en la parte superior de cualquier página de la oficina."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La frase correcta te mostrará que estás en el sitio correcto, una frase incorrecta será una señal: no realices operaciones, debes ponerte en contacto de inmediato con el departamento de seguridad de nuestro sistema."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar anti-phishing:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frase anti-phishing:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los cambios se aplicarán después de la próxima autorización"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de acceso por dirección IP no permitirá que tú (o un atacante) trabajes con el sistema desde una dirección que no esté en la lista de permitidas."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricción de acceso a la oficina por direcciones IP"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar direcciones"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección: "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloque de direcciones: "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu IP: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de direcciones"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde la dirección"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la dirección"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay direcciones"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibir"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la dirección IP"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección IP ingresada incorrectamente"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha especificado ninguna dirección IP o bloque de direcciones."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se permite la autorización desde ninguna dirección IP. No podrás acceder a tu cuenta."])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso por IP"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de:"])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La palabra mágica es una configuración de seguridad adicional."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la palabra mágica está activada, cada vez que inicies sesión el sistema te pedirá que ingreses algunos caracteres (por su número de orden) de la palabra mágica."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra mágica:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntar la palabra mágica:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al ingresar a la oficina:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al pagar a través del comerciante:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al usar la tarjeta:"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palabra mágica"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las claves de un solo uso mejoran significativamente la seguridad del sistema."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el momento de iniciar sesión, el programa te pedirá no solo que ingreses tu usuario y contraseña, sino también la siguiente clave de un solo uso. La activación se realiza secuencialmente. La última clave es válida hasta que crees una nueva serie de claves."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar claves de un solo uso"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de claves"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Claves de un solo uso"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña antigua:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \"Nueva contraseña\" y la \"Confirmación de la contraseña\" no coinciden."])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadena de 4 a 63 caracteres. Se permiten caracteres del alfabeto latino, números y símbolos"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña antigua:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de la contraseña:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La \"Nueva contraseña\" y la \"Confirmación de la contraseña\" no coinciden."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña no debe contener caracteres cirílicos"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear contraseña de confirmación"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña de confirmación"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la página principal"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadena de 4 a 65 caracteres. Se permiten caracteres del alfabeto latino, números y símbolos"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de que olvides tu contraseña para acceder a la oficina, podrás recuperarla respondiendo a la pregunta secreta."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección, puedes cambiar la pregunta/respuesta secreta."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta secreta"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu pregunta:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta secreta"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu pregunta"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saldo "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par de intercambio:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de intercambio:"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia de mercado: se utilizará el intercambio al mejor precio disponible en el momento de la ejecución de la solicitud. Atención, la cantidad a recibir puede cambiar y diferir de la calculada dependiendo de las solicitudes en el momento del intercambio."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia a precio fijo: se realiza de acuerdo con la cantidad y el tipo de cambio que especifiques. Se colocará una orden. Si el tipo de cambio especificado difiere mucho del mercado, el cierre de la orden puede tardar mucho tiempo."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio (estimado):"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El tipo de cambio no está definido, "])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se recomienda especificar el tipo de cambio manualmente"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar todo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión:"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el mercado"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(intercambio rápido al mejor precio disponible)"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio fijo "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(colocar orden con el tipo de cambio especificado)"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuentas adecuadas"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio en 24 horas"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variaciones en 24 horas"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen de operaciones en 24 horas"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El monto no puede ser igual a cero!"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiar"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver los datos del resumen, selecciona un par de intercambio"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pueden intercambiar activos iguales"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolsa"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado. Resumen"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas similares"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas de contraparte"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dan"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciben"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis órdenes"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimas operaciones"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregado"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y hora"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comisión"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ofertas"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay operaciones"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay órdenes"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado en su totalidad"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento no iniciado"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento iniciado"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado parcialmente"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado en su totalidad"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado parcialmente y cancelado"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las órdenes"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las órdenes"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comisión"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay órdenes"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mínimo entregado"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto máximo entregado"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto mínimo recibido"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto máximo recibido"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de intentos de transacción"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo entregado"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo recibido"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de crédito"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de sub-distribuidores"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no seleccionada"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período desde"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período hasta"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de fecha no seleccionado"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalización"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qué parte está fija y cuál es variable"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto entregado fijo"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto recibido fijo"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de orden"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El usuario desea intercambiar rápidamente al mejor precio disponible"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio a tipo de cambio fijo"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento no iniciado"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procesamiento iniciado"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado parcialmente"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado en su totalidad"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelado en su totalidad"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado parcialmente y cancelado"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo entregado"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo recibido"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de fecha"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entregar monto"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir monto"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operaciones"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ofertas"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del vendedor"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de la oferta"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cripto-activo"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor verificado"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo para usuarios verificados"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede realizar una operación contigo mismo"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar activo"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº  "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cripto-activos"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cripto-activos"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión de tokens"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primario"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secundario"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparado con otro activo"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información detallada sobre el criptoactivo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar datos sobre un activo criptográfico"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema adicional"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar criptoactivo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los tokens se pueden comprar en una criptomat"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los tokens se pueden vender en una criptomat"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrando fichas"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para un usuario específico"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de usuario"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El nombre es el nombre completo del criptoactivo, como será visto por todos: el propio emisor, compradores, vendedores, procesadores, etc."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ticker es el nombre corto del cripto-activo, una abreviatura de 3 a 10 caracteres"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige la categoría que más se ajusta a tu cripto-activo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatos admitidos: png, jpg, jpeg, svg. \nTamaño de archivo no superior a 1 MB, 500*500 píxeles"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe por qué el emisor emite este cripto-activo, en qué sector trabaja o trabajará, cuál es su misión"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logotipo:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo/misión/propósito:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo por unidad:"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de decimales para la cantidad. Por ejemplo, la precisión de la mayoría de las monedas fiduciarias como el dólar estadounidense o el euro es 2."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de unidades del cripto-activo emitido. La suma de la emisión estará disponible en tu cuenta después de completar la operación de creación del cripto-activo."])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo declarado por unidad:"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantía:"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta:"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persona responsable:"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisión de emisión del cripto-activo:"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar operaciones al blockchain:"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de blockchain:"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar operaciones con el cripto-activo:"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones/Sanciones:"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica a los ciudadanos o residentes fiscales de qué países de la lista está prohibido usar el cripto-activo en sus operaciones: "])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras restricciones y condiciones:"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de hosting:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del libro blanco:"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico:"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram:"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter:"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook:"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros contactos:"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cripto-activo"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre y propósito"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financieras"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisor"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar al blockchain"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límites y restricciones"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No definido"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculación fija a monedas fiduciarias y otros activos"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisor (Persona que emite el cripto-activo)"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferente (Persona que distribuye el cripto-activo"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remitente"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yo (activar patrocinio)"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocación del cripto-activo"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un cripto-activo significa una representación digital de valor o derecho que puede ser transferida y almacenada electrónicamente utilizando tecnología blockchain. Los cripto-activos NO incluyen monedas fiduciarias, valores y otros instrumentos financieros y activos sujetos a regulación estatal separada."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar activo criptográfico"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de la negativa a aceptar"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta del oferente"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta del oferente en la moneda vinculante"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El oferente es un representante del emisor de un activo en operaciones de compra/venta en el mercado primario. \nLa cuenta es pasiva. \nPuede ser una cuenta de emisión de activos"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cuenta es pasiva, vinculada en moneda. \nDebe pertenecer al mismo usuario que la cuenta anterior."])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir a los usuarios comprar un activo en cajeros automáticos"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de compra posventa"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones del mercado secundario se realizan a través de pasarelas."])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario de ventas de posventa"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones del mercado secundario se realizan a través de pasarelas."])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transacciones con un activo en el intercambio."])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir transacciones de activos vía P2P"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si es un empresario individual o un representante de una entidad jurídica, debe descargar el archivo de decisión sobre la liberación de un activo digital en formato PDF. \nLa solución debe contener todas las características principales del activo virtual y estar firmada con la firma electrónica del empresario individual o de esta persona jurídica. \nPuede encontrar un ejemplo de una decisión sobre la emisión de un activo digital en las páginas de nuestro sitio web en la sección \\\"Documentos\\\""])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocar un activo virtual"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de emisión sustituta"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar entre cuentas de emisor"])},
        "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto a cancelar:"])},
        "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin cuenta"])},
        "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una cuenta"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos virtuales"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de notificaciones"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre operaciones"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización (iniciar sesión en la oficina personal, aplicación móvil, etc.)"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar al realizar \"Operaciones financieras con tarjeta\""])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastos (cualquier pago, transferencia, pago de servicios, etc.)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción de fondos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción de correo interno"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recepción de factura"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de configuración de seguridad"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar operaciones con un código del mensaje"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar autorización con un código del mensaje"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar al realizar \"Operaciones financieras con tarjeta\""])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar cambio de configuración de seguridad con un código del mensaje"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar gastos con un código del mensaje"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios no guardados, ¿guardar?"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No guardar"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de recepción"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de procesamiento"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de pagos"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de los cuales exitosos"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de los cuales fallidos"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos masivos"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proveedor de servicios"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er parámetro"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron pagos"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes realizar varios pagos al mismo tiempo. \nPara hacer esto, primero debe crear un archivo con una descripción de los pagos en formato \"XLS\", \"XLSX\", \"CSV\"."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análisis de archivo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el archivo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de pagos: "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por un monto total de: "])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo de pagos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizar pagos"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar archivo de pagos"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagos y transferencias masivas"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servicio de pagos masivos permite transferir fondos rápidamente a otros usuarios y realizar pagos a proveedores de servicios."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En la sección"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar archivo de pagos"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["puedes cargar un archivo en formato Excel (XLS, XLSX, CSV) o XML."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de tareas"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permite ver el estado de las solicitudes de pagos masivos creadas anteriormente."])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes crear un archivo en el editor de hojas de cálculo Excel."])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar ejemplo de archivo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas importantes:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato del archivo - CSV, XLS o XLSX."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El archivo no debe tener encabezados."])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para que los números largos no se conviertan en formato exponencial (por ejemplo, \"5,46546E+15\") y no se recorten los ceros iniciales, puedes anteponer una comilla simple (') al número, o establecer el formato de celda como texto antes de ingresar los datos."])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de columnas para pagos a proveedores de servicios"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de columnas en el archivo:"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del operador"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica de la lista de operadores proporcionados"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". (Para todos los operadores de telefonía móvil, se puede usar el código de operador 7000 - en este caso, el operador se determinará automáticamente por el número de teléfono.)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". El monto se especifica en el ticker del activo desde el cual se realiza la transferencia. Si no hay tal ticker, se aplicará automáticamente el coeficiente de conversión del sistema."])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario al pago"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". No es obligatorio, se puede dejar en blanco; longitud máxima de 255 caracteres."])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica el valor del parámetro requerido por el operador, por ejemplo, el número de teléfono. Muchos operadores se conforman con un parámetro de pago, pero si hay más de un parámetro, debes especificarlos todos en las columnas adyacentes a la primera."])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes ver los parámetros de pago yendo al pago de cualquier operador:"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de columnas para transferencias masivas a usuarios"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del operador"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica 0 o no se especifica en absoluto."])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". El monto se muestra en el ticker de la cuenta desde la cual se transfiere el activo. Si no hay tal ticker, se aplicará automáticamente el coeficiente de conversión del sistema."])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario al pago"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". No es obligatorio, se puede dejar en blanco; longitud máxima de 255 caracteres."])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta del destinatario"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Aquí se especifica el número de cuenta del usuario al que se debe realizar la transferencia (20 dígitos)."])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de columnas para la emisión masiva de préstamos por parte del agente de crédito"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Esta línea indica que los pagos son préstamos."])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". El monto se especifica en la moneda del producto crediticio."])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario del préstamo"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". No es obligatorio, se puede dejar en blanco; longitud máxima de 255 caracteres."])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del prestatario"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Ten en cuenta que el prestatario debe tener un pasaporte digital Mfo-BorrowPerson."])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del producto crediticio"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Puedes verlo en la lista de productos crediticios para la emisión manual de préstamos."])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Simplemente el número de períodos (los períodos específicos utilizados están definidos en el producto crediticio, por ejemplo, pueden ser días, semanas, meses, etc.)"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del operador (número de formulario)"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Completar este y los siguientes campos no es obligatorio, si no se completan, el prestatario podrá retirar el préstamo más tarde a través de su oficina personal."])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar en caso de error"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Cancelar (1) el préstamo otorgado si la transacción de retiro falla, o dejarlo para el retiro autónomo posterior (0)."])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". Se especifica el valor del parámetro requerido por el operador, por ejemplo, el número de teléfono. Muchos operadores se conforman con un parámetro de pago, pero si hay más de uno, debes especificarlos todos en las columnas adyacentes a la primera."])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago 2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro de pago N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado "])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último mensaje"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay solicitudes"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prioridad"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asunto"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto del mensaje"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes cargar uno o varios archivos. Tamaño máximo del archivo: 10 MB."])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baja"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alta"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte técnico"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitudes activas"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva solicitud"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso por el operador"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud creada"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En proceso, espera una respuesta del operador"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud cerrada"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud cerrada"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay mensajes nuevos"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensaje nuevo del operador"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay mensajes nuevos"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar al archivo"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las solicitudes"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recarga"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operador"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay plantillas"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la plantilla nº"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar plantilla "])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renombrar"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del corresponsal"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Cuenta / Correo electrónico / Teléfono"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / Cripto-dirección / Correo electrónico / Teléfono"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos generales"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de pago"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma de control"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis tiendas"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron tiendas"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear código de widget"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear enlace de pago"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar tienda"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Realmente quieres eliminar la tienda"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar tienda"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de pagos"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar tienda"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una tienda electrónica registrada en el sistema puede recibir pagos de clientes a través del comerciante de nuestro sistema."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se registran como tiendas electrónicas, puntos de intercambio y cualquier otro recurso en línea que desee recibir pagos."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre o marca comercial"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección URL de la tienda"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breve descripción de la tienda"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de servicio"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda activa"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clave secreta"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir pagos automáticos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar resultados al script"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del script"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retorno después de pago exitoso"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después de un pago fallido"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos 30 caracteres."])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se usa para verificar la integridad y autenticidad de los datos transmitidos por el sistema de pago (al enviar al usuario del comerciante) y por la tienda (al recibir los resultados del pago)"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la lista de tiendas"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de facturas"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron facturas"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tienda"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo interno"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo externo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre EN"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinónimos"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si se completa, el anuncio estará disponible solo para usuarios de los países indicados"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular al activo"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de transferencia"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera (min)"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El anuncio está activo."])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo por enlace"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo para usuarios verificados"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A la anterior"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publicar"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restricciones"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de anuncio"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu activo externo"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vincular"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducir tipo de cambio"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cuenta en tal activo"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Especificar tipo de cambio"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio correcto"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reducir tipo de cambio"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo mayor que el máximo"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dónde y cómo deseas recibir la transferencia. Por ejemplo: Transfiere en la aplicación bancaria al número de teléfono +349990001111 (destinatario Alexander N.)"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doy"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibo"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles solicitados"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qué detalles debe proporcionar el tomador para que puedas hacer la transferencia"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un activo"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un activo"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué desea recibir: fondos en un activo interno, por ejemplo, BTC, o en un activo externo, por ejemplo, TOKEN GOLD?"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escriba un comentario sobre las características de esta transacción, por ejemplo: \\\"El activo externo solo se transfiere en un encuentro personal\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de valores al que pertenece el activo externo, por ejemplo, \\\"Dinero en efectivo\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del activo para mostrar en la plataforma"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El activo también puede ser llamado con palabras de la lista"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparación con un activo existente"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo: litros, piezas, horas, cajas"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por ejemplo: L, pcs, h, caja"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuántos dígitos pueden haber después de la coma en los valores del activo"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha seleccionado la creación de un activo aún no existente, por favor complete sus características"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo que se les da a las partes para cumplir con las condiciones"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un activo interno es un activo que existe dentro del sistema \\\"New Reality\\\". Un activo externo es un activo que no existe dentro del sistema \\\"New Reality\\\"."])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No vinculado"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doy"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razón"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar transacción"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar participación en la transacción"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar transacción"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extender tiempo de espera"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar transacción"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado del usuario en la transacción no definido. Algo salió mal"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar arbitraje"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar arbitraje"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificar sobre transferencia completada"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar recepción de la transferencia"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calificar transacción"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar calificación"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción nº"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoy"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mañana"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar arbitraje"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar arbitraje"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has solicitado arbitraje"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha solicitado arbitraje en su contra"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El arbitraje está en marcha (usted es el demandante)"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El arbitraje está en marcha (usted es el demandado)"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una vez que expire el tiempo, el consentimiento al arbitraje se producirá automáticamente y ya no podrá agregar sus respuestas a las preguntas de arbitraje."])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibir"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibes"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límites"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transacciones"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay transacciones"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ofertas"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficio"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de transacción"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doy"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibo"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay transacciones activas"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis transacciones"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo interno"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del activo"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay anuncios"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis anuncios"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis anuncios"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuevo anuncio"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo externo"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo interno"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptando"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límites"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de publicación"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo en cuenta"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar transacción"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedor"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera para acciones de las partes"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de cambio"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibes"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar transacción"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta no seleccionada"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad fuera del rango: "])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dado"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibido"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["doy"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interno"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recibo"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos internos"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron activos"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos externos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["externo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionado:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internos"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externos"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitar arbitraje"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ayer"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos externos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al activo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período desde"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período hasta"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay activos"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayer"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semana"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Año"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculado al activo"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar criptoactivo externo"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncio"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de cantidad"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera (min)"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncio activo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo por enlace"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible solo para usuarios verificados"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar activo externo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grupo"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinónimos"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Países"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincular al activo"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidades de medida"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abreviado"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisión"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar todo"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cajeros automáticos"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz clic para expandir la tabla a pantalla completa"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdistribuidores"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["crear un cajero automático"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar criptomoneda"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información principal"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principal"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de apertura"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción del lugar de instalación"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normado"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No normado"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de trabajo"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miércoles"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jueves"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viernes"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora de almuerzo"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usar cuentas existentes"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear nuevas cuentas"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información principal"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horario de trabajo (información de referencia)"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron máquinas criptográficas"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número de cajero automático"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["latitud"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["longitud"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Línea de ayuda"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del subdistribuidor"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuentas"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlar"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisa"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta de tránsito"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de una criptomat"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar un criptomat"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prohibir"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitir"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de coleccionistas"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un coleccionista"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede acceder a los saldos de cobro: no hay datos"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de coleccionistas"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar un coleccionista"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permitido"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptación"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRECCIÓN"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar cajero automático"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobranza actuales"])},
      "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver saldos de cobro descargados"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["código pin"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no especifica un subdistribuidor, el cajero automático estará vinculado a usted"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La latitud y la longitud son coordenadas geográficas que se pueden copiar, por ejemplo, de Google Maps introduciendo la dirección del cajero automático."])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación de instalación"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se debe seleccionar al menos una columna para mostrar la tabla."])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
      "79": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir a dispositivos cajeros automáticos"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques electrónicos"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cheque"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creado"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactivo"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay cheques"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron recibos electrónicos"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cheque electrónico"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor nominal de cada cheque"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de cheques"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie:"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número:"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de activación:"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cheques creados"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exportar"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar cheque electrónico"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de crédito"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serie"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de activación"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activar"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ups, hubo un error!"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida en cajero automático"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar colección"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reiniciar"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mostrar colecciones de archivos"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No mostrar colecciones canceladas"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de punto"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin colecciones"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de cobro enviada"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar colección"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar estado de colección"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar colección"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información básica"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo esencial"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispensador"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de punto"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de tarjetas por aplicación"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptador de billetes"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coleccionistas de efectivo"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota para coleccionistas"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre la colección."])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando fuera de orden"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reemplace el casete del aceptador de billetes"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre el casete."])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre las cartas."])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retire todas las tarjetas del casete del dispensador de tarjetas de rechazo."])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retire todas las tarjetas del tubo."])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de punto es un campo obligatorio que debe completarse"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de casete 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes en el casete 1"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad en casete 1"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes en el casete 2"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre la cinta 1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del casete"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre el casete."])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edición de colección"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de casete"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID del casete"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de recogida del casete"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación del casete"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subido"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cajero automático"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De hecho"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario sobre el casete."])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay datos para mostrar"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección de dispositivos"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["los restos son llevados"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servidor"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cajero automático"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de billetes"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispensador"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar/Retraer"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aceptador de billetes"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechazar tarjetas"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarjetas"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actual"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descargado"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldos de cobranza en cajeros automáticos"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección de dispositivos"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["válido"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casete instalado en cajero automático"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["incautado"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["los restos son llevados"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["los restos son llevados"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casete vacío:"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["adhesión:"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información adicional:"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasta la fecha"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar estado"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de cobro ha sido enviada."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de cobro cancelada"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colección aprobada para ejecución"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cobro lo realiza el cajero (el dinero se carga en casetes)"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casetes entregados a los coleccionistas (dinero en camino)"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recogida de efectivo instalada en el cajero automático"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como resultado de una falla, sobrecargado con otra colección."])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recogida fue sustituida por otra recogida en un cajero automático (sigue en camino)"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se contabilizan los saldos de cobranza (la colección se envía al archivo)"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar información sobre el recopilador"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["número"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahorrar"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de la cuenta del oferente"])}
    },
    "ATMauth": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campos del formulario No."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falta la identificación requerida"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has iniciado sesión exitosamente, continúa operaciones en el cajero automático"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar transacciones en cajeros automáticos"])}
    },
    "ChoiseColumns": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar columnas para mostrar"])}
    },
    "FreeReports": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divisa"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear informe"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muestra"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoja"])}
    },
    "ATMDevices": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispositivos de cajero automático"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar dispositivo"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de dispositivo"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar casete"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipo casete"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar dispositivo"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitar casete"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Eliminar dispositivo?"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Eliminar el casete?"])}
    },
    "ListRates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pares de divisas"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un par de divisas"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar par de divisas"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar par de divisas"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comportamiento"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerta"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de actualización"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron pares de divisas"])}
    },
    "RateItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear un par de divisas"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar una actualización"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerta"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda recibida"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir beneficio al curso, %"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de actualización del curso"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del curso, segundos"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervalo de actualización del curso, segundos"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Para qué fecha es válido el curso?"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​de la moneda dada"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alias ​​de la moneda recibida"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puerta"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es necesario elegir una moneda y, además, solo una: entregada o recibida."])}
    },
    "SupportNew": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear una solicitud de soporte"])}
    },
    "ShowInfoVA": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos de criptoactivos"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del problema"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corazón"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permisos"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propósito/Misión/Propósito"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Características financieras"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categoría"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exactitud"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenedor de una obligación"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editor"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Costo unitario"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras en cajeros automáticos"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventas en cajeros automáticos"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones con activos en bolsa."])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacciones de activos vía P2P"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de alojamiento"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del Libro Blanco"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferente"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otros contactos"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ir"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta personal"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de negocios"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segundo nombre: (si hay)"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas, guiones y apóstrofos."])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas, guiones, espacios y apóstrofos."])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ninguno"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas, comillas, espacios, números y apóstrofos."])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Mi Compañía\" Ltd"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se permiten letras latinas (mayúsculas y minúsculas), números, símbolos _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir contraseña"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las contraseñas no coinciden"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datos del usuario"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proporciona una dirección de correo electrónico o un número de teléfono válido. El número de teléfono debe ingresarse en formato internacional, por ejemplo: +499001234567."])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te has registrado con éxito"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahora puedes"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciar sesión en tu cuenta personal"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu cuenta será revisada por nuestro empleado, te informaremos sobre los resultados de su activación más tarde"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha enviado un correo electrónico con un enlace para confirmar el registro"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tu dirección de correo electrónico"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Después del primer inicio de sesión, se te pedirá que confirmes tu número de teléfono"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactos"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más detalles"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de usuarios"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éxito"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["transacción"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros generales"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios en los saldos de las cuentas"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al final de la operación:"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al inicio de la operación:"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros adicionales"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones en blockchain"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente intento:"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protección de la transacción"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la protección:"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de protección:"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetros de la plantilla"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la plantilla:"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar operación"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operación"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar plantilla"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de transacción"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corresponsal"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de pago"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de formulario de pago"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingreso/Gasto"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambios en los saldos de las direcciones"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar crédito"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver dinero al remitente"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operación sospechosa"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de acción:"])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario no encontrado"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la contraseña de confirmación"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repetir operación"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de operaciones"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de cuentas"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éxito"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error desconocido"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plantillas"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis activos"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información sobre el nuevo activo"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión adicional"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de activos virtuales"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a cadena de bloques"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada:"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar dirección"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Localidad"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distrito"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Municipio"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciudad"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territorio intraurbano"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asentamiento"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calle"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elemento adicional de dirección"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código postal"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de soporte técnico"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar mensaje"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes cargar uno o varios archivos. Tamaño máximo de archivo - 10MB."])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texto del mensaje"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creación de widget para"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generar"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago en"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del pago"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones al éxito"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones al fallo"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generación de código/enlace de pago"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de operación de la tienda"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parámetro adicional"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de éxito"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de fallo"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL de notificación"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL al que volverá el cliente después del pago exitoso"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL al que volverá el cliente después del fallo"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL donde se enviará la notificación de los resultados del pago"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fijar cantidad"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificar"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace de pago"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentación"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración de la cuenta de pago en minutos, por defecto 60"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duración del enlace"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulario"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no fija el importe, el pagador podrá introducirlo de forma independiente (adecuado, por ejemplo, para pagos benéficos)"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar tienda"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si ves esto, el formulario no se recibió (pero no deberías verlo)"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emisión adicional"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptoactivo"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta - fuente de cobertura"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta de débito de comisión"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problema adicional"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentario"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño del problema actual"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIMBÓLICO"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revestimiento"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cálculo de cobertura"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinculante:"])}
        },
        "Warning": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A deducir"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A acreditar"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se puede calcular la cantidad"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar archivo"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formatos permitidos:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato desconocido"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño de archivo demasiado grande, tamaño máximo:"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona o arrastra aquí los archivos para cargar"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargar"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivos cargados"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archivos"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Borrar"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultado:"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción:"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar estado"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlaces útiles"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blockchain:"])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otras configuraciones de seguridad"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se requiere confirmación"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La autorización debe ser confirmada con una clave de un solo uso"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La autorización debe ser confirmada con una palabra mágica"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El registro debe ser confirmado con un código de mensaje de texto"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadena de"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["caracteres"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor no permitido"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haga clic para seleccionar qué columnas mostrar"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crypto ID:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buen día"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buenas tardes"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buenas noches"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buenas noches"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrado:"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por página"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar pago"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calcular comisión de reembolso"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devolver pago"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash en blockchain"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riesgo AML"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A acreditar"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si aceptas los términos modificados, confirma el crédito:"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si estos términos no te convienen, puedes devolver los fondos a la cuenta del remitente, menos los costos de devolución:"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por la cantidad de"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiene riesgo AML"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["por lo tanto, la comisión ha sido recalculada"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de esta manera, la cantidad a acreditar es"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron nuevos ingresos"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha del pago"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reembolso"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atrás"])}
      }
    }
  }
}